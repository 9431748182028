<template>
  <v-menu v-model="showHistory" offset-y z-index="1000000000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" v-bind="attrs" v-on="on">
        <v-icon>mdi-clock-time-five-outline</v-icon>
      </v-btn>
    </template>

    <v-card max-width="20rem">
      <v-list class="pt-4 pb-0">
        <v-list-item class="pa-0 mb-0">
          <v-list-item-content class="pa-0 px-5">
            <v-list-item-title class="title font-weight-bold text-center">
              {{ $t('contract.document_history') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list v-if="history.length === 0">
        <v-list-item class="py-0 mx-3 px-0">
          <v-list-item-content class="pa-0 px-5">
            <v-list-item-title class="font-weight-bold text-center">
              {{ $t('contract.history.no_records') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list v-else class="pt-0 pb-0" three-line style="max-height: 40rem; overflow: auto">
        <v-list-item
          v-for="(item, index) in history"
          :key="index"
          class="py-0 mx-3 px-0 document-history__row"
        >
          <v-row align="center" justify="start">
            <v-col class="col-2">
              <SAvatar
                :size="40"
                :firstname="
                  item.docs_person === null
                    ? item.logged_user.firstname
                    : item.docs_person.firstname
                "
                :lastname="
                  item.docs_person === null ? item.logged_user.lastname : item.docs_person.lastname
                "
                :profileImage="getUserAvatar(item)"
                :background-color="item.color"
              />
            </v-col>

            <v-col class="col-10">
              <v-row align="center" justify="start" no-gutters>
                <v-col class="col-12 pb-0">
                  <div class="font-weight-medium">
                    {{ item.event }}
                  </div>
                </v-col>

                <v-col class="col-12 pb-0">
                  <div
                    class="font-weight-normal grey--text document-history__row--item"
                    :title="getCurrentInfo(item)"
                  >
                    {{ getCurrentInfo(item) }}
                  </div>
                </v-col>

                <v-col class="col-12 pb-0">
                  <div class="font-weight-normal grey--text document-history__row--item">
                    {{
                      item.docs_person === null ? item.logged_user.email : item.docs_person.email
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';
import SAvatar from '@/common/components/SAvatar';

export default {
  name: 'DocumentHistory',
  components: { SAvatar },
  props: {
    history: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      showHistory: false,
    };
  },
  methods: {
    getCurrentInfo(item) {
      return `${
        null === item.docs_person ? item.logged_user.firstname : item.docs_person.firstname
      } ${
        null === item.docs_person ? item.logged_user.lastname : item.docs_person.lastname
      } ${getFormattedDateByLocale({
        date: item.created_at,
        options: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        },
      })}`;
    },
    getUserAvatar(item) {
      const userImage =
        null === item?.docs_person ? item?.logged_user.userAvatar : item?.docs_person.userAvatar;

      return userImage || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.document-history {
  &__row {
    border-bottom: 1px solid #d4dfef;

    :deep(&--item) {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
    }
  }

  &__row:last-child {
    border-bottom: unset;
  }
}
</style>
