<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      :loading="loading"
      type="article"
      width="100%"
      height="100%"
    />
    <shadow-root v-if="showShadowRoot && !loading" class="document-view__template">
      <template>
        <div id="contract-template-content" v-html="source" />
      </template>
    </shadow-root>
    <template v-if="!showShadowRoot && !loading">
      <TemplateAutocomplete
        :autoFocusNextInput.sync="autoFocusNextInput"
        @prefillData="prefillData"
      />
      <div class="content-box">
        <div class="content-box__data">
          <div
            id="contract-template-content__edit"
            class="document-view__template"
            v-html="source"
          />
        </div>
      </div>
      <EditTemplateModal
        v-model="showEditTemplateModal"
        :contentType="contentType"
        :contentData="contentData"
        @saveContentType="saveContentType"
        @resetContentType="resetContentType"
        @changePartRadioValue="changePartRadioValue"
        @savePartContentType="savePartContentType"
        @deletePart="deletePart"
      />
      <ContentLegend :isProposer="isProposer" />
    </template>
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <v-btn
          v-if="
            contract.attachments.length > 0 &&
            !areAllTemplatesCompleted &&
            !isProposer &&
            'completed' !== contract.state
          "
          :disabled="!isTemplateCompleted"
          color="primary"
          large
          @click="sendTemplateData"
        >
          {{ $t('contract.save.continue_to_next_contract') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { internalApiRequest } from '@/shared/services/apiService';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import { getFormattedDate } from '@/common/reusable/dateFunctions';

import { ContractService } from '@/services/ContractService';
import { useTemplateFieldsStore } from '@/features/contract/stores/templateFieldsStore';

import ContentLegend from '@/components/ContentLegend';
import EditTemplateModal from '@/features/contract/components/EditTemplateModal';
import TemplateAutocomplete from '@/features/contract/components/TemplateAutocomplete';
import { mapGetters, mapActions } from 'vuex';
import { publicApiRequest } from '@/services/ApiService';

const $ = window.jQuery;
const jQuery = window.jQuery;

export default defineComponent({
  name: 'DocumentTemplateView',
  components: { TemplateAutocomplete, EditTemplateModal, ContentLegend },
  props: {
    source: {
      type: String,
      required: true,
    },
    selectedContractId: {
      type: Number,
    },
  },
  data() {
    return {
      activeElement: '',
      activeContent: '',
      contentOptions: [],
      options: [],
      data: [],
      done: 0,
      total: 1,
      contentType: '',
      contentTop: 0,
      contentData: {},
      totalWithoutOptional: 0,
      doneWithoutOptional: 0,
      showEditTemplateModal: false,
      showFinish: false,
      autoFocusNextInput: false,
      loading: false,
    };
  },
  setup() {
    const templateFieldsStore = useTemplateFieldsStore();
    const getTemplateFields = computed(() => templateFieldsStore.getTemplateFieldsData);
    const isTemplateCompleted = computed(() => templateFieldsStore.isTemplateCompleted);
    const completedTemplates = computed(() => templateFieldsStore.getCompletedTemplates);
    const allContractTemplates = computed(() => templateFieldsStore.getAllContractTemplates);
    const areAllTemplatesCompleted = computed(() => templateFieldsStore.areAllTemplatesCompleted);

    return {
      templateFieldsStore,
      getTemplateFields,
      isTemplateCompleted,
      completedTemplates,
      allContractTemplates,
      areAllTemplatesCompleted,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    contentFilled() {
      return (
        'form' === this.contract.type &&
        this.contract.template &&
        '' === this.contentType &&
        this.done === this.total &&
        this.loaded &&
        this.showFinish
      );
    },
    isHighlited() {
      return this.highlight;
    },
    isProposer() {
      return this.contract.current_sign_identity
        ? this.contract.current_sign_identity?.is_proposer
        : this.contract.sign_identity?.is_proposer;
    },
    isSignedByCounterparty() {
      return this.contract.sign_identities.some(
        (signIdentity) => !signIdentity.is_proposer && signIdentity.is_signed,
      );
    },
    isApprovedByCounterparty() {
      return this.contract.sign_identities.some(
        (signIdentity) => !signIdentity.is_proposer && signIdentity.is_approved,
      );
    },
    showShadowRoot() {
      return (
        !this.getTemplateFields.length ||
        this.isProposer ||
        this.isSignedByCounterparty ||
        this.isApprovedByCounterparty
      );
    },
  },
  watch: {
    contentFilled() {
      if (this.contentFilled) {
        this.setIsCreateContentFilled();

        return;
      }

      this.resetIsCreateContentFilled();
    },
    activeContent: function () {
      if (this.contentType != 'options' && this.activeContent && this.activeContent.length > 1) {
        jQuery(this.activeElement).html(this.activeContent);
      }
    },
    doneWithoutOptional: function () {
      if (this.doneWithoutOptional == this.totalWithoutOptional) {
        this.showFinish = true;
        this.closedSuccessBox = false;
      }
    },
    totalWithoutOptional: function () {
      if (this.doneWithoutOptional == this.totalWithoutOptional) {
        this.showFinish = true;
        this.closedSuccessBox = false;
      }
    },
    activeElement: function (newElem, oldElem) {
      if (oldElem) oldElem[0].classList.remove('selected');

      if (this.activeElement) {
        this.activeElement.closest('con-section').addClass('editing');

        if (!this.activeElement[0].classList.contains('selected')) {
          this.focusConInput(this.activeElement[0]);
        }
      }
    },
    contentType() {
      if ('' === this.contentType) {
        this.activeContent = '';
        this.activeElement = '';
      }
    },
    showFinish: function () {
      if (
        this.contract.template &&
        '' == this.contentType &&
        this.doneWithoutOptional == this.totalWithoutOptional &&
        this.loaded &&
        this.showFinish &&
        window.innerWidth > 991
      ) {
        jQuery(document).ready(function ($) {
          $('html, body').animate({ scrollTop: 0 }, 600);
        });
      }
    },
    selectedContractId: function (selectedContractId, oldContractId) {
      if (selectedContractId && selectedContractId !== oldContractId) {
        this.templateFieldsStore.resetTemplateFieldsStore();

        this.getTemplateParams(selectedContractId)
          .then(() => {
            this.fetchJS();
            this.recalculateElements();
          })
          .catch((error) =>
            this.$notification.error(getErrorResponseMessage(error.response?.data || error)),
          );
      }
    },
    isTemplateCompleted: function (status) {
      if (status) {
        this.templateFieldsStore.setCompletedTemplates(
          this.selectedContractId || this.contract?.id,
        );
      }
    },
  },
  mounted() {
    this.loading = true;
    this.resetIsCreateContentFilled();

    this.getTemplateParams(this.selectedContractId || this.contract.id)
      .then(() => {
        this.loading = false;
        const css = `
      #contract-template-content {
        color: #4a4a4a;
      }
      #contract-template-content p {
        margin-top: 20px;
      }
      #contract-template-content p.centered {
        text-align: center;
      }
      #contract-template-content h2 {
        text-align: center;
        margin: 30px 0 0 0;
      }
      #contract-template-content h1 {
        display: none;
      }
      #contract-template-content .table {
        width: 100%;
        margin-top: 30px;
        border-spacing: 0;
        font-size: 15px;
        color: #4a4a4a;
        letter-spacing: -0.03px;
        line-height: 1.7;
        font-weight: 300;
      }
      #contract-template-content .table tr:last-of-type td {
        border-bottom-color: #000;
      }
      #contract-template-content .table tr td {
        border: 1px solid #000;
        border-right-color: transparent;
        border-bottom-color: transparent;
        padding: 5px 10px;
      }
      #contract-template-content .table tr td:last-of-type {
        border-right-color: #000;
      }
      #contract-template-content con-options,
      #contract-template-content con-text,
      #contract-template-content con-textarea,
      #contract-template-content con-number,
      #contract-template-content con-date,
      #contract-template-content con-section {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        cursor: auto;
        font-weight: 300;
        color: black;
        text-decoration: none;
      }
    `;

        this.$nextTick(() => {
          const shadowWrapper = document.querySelector('.document-view__template');
          const wrapper = shadowWrapper?.shadowRoot?.querySelector('#contract-template-content');

          if (wrapper && (this.isProposer || this.isSignedByCounterparty)) {
            wrapper.insertAdjacentHTML('beforebegin', `<style>${css}</style>`);
            document.head.innerHTML += css;
          }
        });

        this.fetchJS();
        this.recalculateElements();
      })
      .catch((error) =>
        this.$notification.error(getErrorResponseMessage(error.response?.data || error)),
      );
  },
  methods: {
    ...mapActions({
      setIsCreateContentFilled: 'setIsCreateContentFilled',
      resetIsCreateContentFilled: 'resetIsCreateContentFilled',
    }),
    async getTemplateParams(selectedContractId) {
      if (this.isProposer && !selectedContractId) {
        return;
      }

      let templateContracts =
        this.contract.attachments > 0
          ? this.contract?.attachments.filter((contract) => 'form' === contract.type)
          : [this.contract];

      this.templateFieldsStore.setContractTemplates(templateContracts);

      if ('draft' !== this.contract?.state && 'pending' !== this.contract?.state) {
        return;
      }

      if (this.$route?.params?.hash) {
        await this.fetchContractTemplatesUnregistered(
          selectedContractId,
          templateContracts,
          this.$route.params.hash,
        );

        this.data = this.getTemplateFields;
      } else {
        await this.fetchContractTemplatesRegistered(selectedContractId, templateContracts);

        this.data = this.getTemplateFields;
      }
    },
    async updateTemplateFieldsData(selectedContractId) {
      if (!this.getTemplateFields.length) {
        return;
      }

      const newTemplateData = this.getTemplateFields
        .filter((field) => !!field?.value)
        .map((templateField) =>
          Object.assign(
            {},
            {
              id: templateField.id,
              value: templateField.value,
            },
          ),
        );

      if (0 === newTemplateData.length) {
        return;
      }

      if (
        this.contract.current_sign_identity?.workspace_id ||
        this.contract?.sign_identity?.workspace_id
      ) {
        return internalApiRequest({
          method: 'PATCH',
          path: `api/v2/contract/${selectedContractId}/form/answers`,
          data: newTemplateData,
        })
          .then(() => this.$notification.success(this.$t('general.data_added')))
          .catch((error) => this.$notification.error(error.response?.data || error));
      }

      return publicApiRequest({
        authorization: 'ApiToken',
        authToken: this.$route.params.hash,
        method: 'PATCH',
        endpoint: `api/client/v1/contract/${selectedContractId}/form/answers`,
        data: newTemplateData,
      })
        .then(() => this.$notification.success(this.$t('general.data_added')))
        .catch((error) => this.$notification.error(error.response?.data || error));
    },
    async fetchContractTemplatesUnregistered(contractId, contracts, authToken = null) {
      const templatePromises = [];

      for (let contract of contracts) {
        if (
          contract?.sign_identities?.some(
            (signIdentity) =>
              !signIdentity.is_proposer && (signIdentity.is_signed || signIdentity.is_approved),
          )
        ) {
          this.templateFieldsStore.setCompletedTemplates(contract.id);
          continue;
        }

        const promise = publicApiRequest({
          method: 'GET',
          authorization: 'ApiToken',
          authToken: authToken,
          endpoint: `api/client/v1/contract/${contract.id}/form/params`,
        }).then(async (response) => {
          let templateFields = response;

          if (contractId === contract.id) {
            this.templateFieldsStore.setTemplateFieldsData(response);
          }

          if (0 === templateFields.length || contract.sign_identity?.is_signed) {
            this.templateFieldsStore.setCompletedTemplates(contract.id);
          }

          await publicApiRequest({
            method: 'GET',
            authorization: 'ApiToken',
            authToken: authToken,
            endpoint: `api/client/v1/contract/${contract.id}/form/answers`,
          }).then((response) => {
            if (contractId === contract.id) {
              this.templateFieldsStore.updateTemplateFieldData(response);
            }

            let templateAnswersFields = response;

            templateFields.forEach((templateField) => {
              const currentValue = templateAnswersFields?.find(
                (answerField) => `${answerField.id}` === `${templateField.id}`,
              );

              if (currentValue) {
                templateField.value = currentValue?.value;
              }
            });

            if (!templateFields.some((field) => !field.value && !field.optional)) {
              this.templateFieldsStore.setCompletedTemplates(contract.id);
            }
          });
        });

        templatePromises.push(promise);
      }

      await Promise.allSettled(templatePromises);
    },
    async fetchContractTemplatesRegistered(contractId, contracts) {
      const templatePromises = [];

      for (let contract of contracts) {
        if ('form' !== contract.type) {
          continue;
        }

        if (
          contract?.sign_identities?.some(
            (signIdentity) => !signIdentity.is_proposer && signIdentity.is_signed,
          )
        ) {
          this.templateFieldsStore.setCompletedTemplates(contract.id);
          continue;
        }

        const promise = internalApiRequest({
          method: 'GET',
          path: `api/v2/contract/${contract.id}/form/params`,
        }).then(async (response) => {
          let templateFields = response;

          if (contractId === contract.id) {
            this.templateFieldsStore.setTemplateFieldsData(response);
          }

          if (0 === templateFields.length || contract.current_sign_identity?.is_signed) {
            this.templateFieldsStore.setCompletedTemplates(contract.id);
          }

          await internalApiRequest({
            method: 'GET',
            path: `api/v2/contract/${contract.id}/form/answers`,
          }).then((response) => {
            if (contractId === contract.id) {
              this.templateFieldsStore.updateTemplateFieldData(response);
            }

            let templateAnswersFields = response;

            templateFields.forEach((templateField) => {
              const currentValue = templateAnswersFields?.find(
                (answerField) => `${answerField.id}` === `${templateField.id}`,
              );

              if (currentValue) {
                templateField.value = currentValue?.value;
              }
            });

            if (contract?.current_sign_identity?.is_proposer) {
              this.templateFieldsStore.setCompletedTemplates(contract.id);
            } else {
              if (!templateFields.some((field) => !field.value && !field.optional)) {
                this.templateFieldsStore.setCompletedTemplates(contract.id);
              }
            }
          });
        });

        templatePromises.push(promise);
      }

      await Promise.allSettled(templatePromises);
    },
    sendTemplateData() {
      let currentContract = this.selectedContractId ? this.selectedContractId : this.contract.id;

      this.updateTemplateFieldsData(currentContract).then(() => {
        let nextTemplate = this.allContractTemplates.find(
          (template) =>
            template.id != currentContract && !this.completedTemplates.includes(template.id),
        );

        if (nextTemplate && this.selectedContractId) {
          return this.$router.push({
            name: 'contractView',
            params: {
              hash: this.$route.params.hash,
            },
            query: {
              contract_id: nextTemplate.id,
            },
          });
        }

        if (nextTemplate) {
          return this.$router.push({
            name: 'documentsDetail',
            params: {
              contract_id: nextTemplate.id,
              workspace_id: nextTemplate.current_sign_identity?.workspace_id,
            },
          });
        }
      });
    },
    saveContentType(event, updateData, contentType) {
      const isResetAction = !!event && 'reset' === event.type;
      this.activeContent = updateData?.value;

      if ('textarea' === contentType) {
        if (0 === updateData?.value?.trim().length && this.isActiveElementRequired()) {
          this.$notification.warning(this.$t('contract.form.required_save'));
          return;
        }
      } else {
        if (this.activeElement) {
          const conSection = this.activeElement.parents('con-section');
          if (conSection && conSection.hasClass('hasIdentity')) {
            window.dataLayer.push({ event: 'contract_proposal_header_completed' });
          }
        }
      }

      if ('' === updateData && this.isActiveElementRequired()) {
        this.$notification.warning(this.$t('contract.form.required_save'));
      }

      const vm = this;

      jQuery(document).ready(function ($) {
        const $activeElement = $(vm.activeElement);

        let eddited;

        if (isResetAction) {
          vm.activeContent = '';
          $activeElement.addClass('skipped');
        }

        if ('date' === vm.contentType) {
          $activeElement.addClass('edited');
          vm.activeContent = getFormattedDate({
            date: updateData.choiceDate,
            format: 'DD.MM.YYYY',
          });
        }

        if ('options' === vm.contentType) {
          $activeElement.find('div.edited').remove();
          eddited = $('<div class="edited">' + vm.activeContent + '</div>');
          $activeElement.append(eddited);

          $activeElement.find('con-option').each(function () {
            let optionId = $(this).attr('con-id');
            if (optionId != updateData?.optionId) {
              vm.templateFieldsStore.setHiddenOptionField(optionId);
            }
          });

          if ('position' == $activeElement.attr('bind')) {
            if (!$activeElement.is('[bind]')) {
              $("[bind='position']").each(function () {
                $(this)
                  .closest('con-section')
                  .append('<div class="trash position"><span class="icon icon-bin"></span></div>');
              });
            }
          } else if ('false' == $activeElement.closest('con-part').attr('hideable')) {
            if (!$activeElement.is('[bind]')) {
              $activeElement
                .closest('con-section')
                .append('<div class="trash section"><span class="icon icon-bin"></span></div>');
            }
          } else if ($activeElement.closest('con-part').find('.trash').length < 1) {
            if (!$activeElement.is('[bind]')) {
              $activeElement
                .closest('con-part')
                .append('<div class="trash"><span class="icon icon-bin"></span></div>');
            }
          }

          $activeElement.addClass('edited');
        } else {
          if (updateData?.value?.trim().length > 0 && 'label' !== updateData?.value) {
            $activeElement.html(vm.activeContent).addClass('edited').removeClass('skipped');
          } else {
            $activeElement.html($activeElement.attr('label'));
            if (isResetAction) {
              $activeElement.addClass('edited');
            } else {
              $activeElement.removeClass('edited');
            }
          }
        }

        let data_item;

        if (vm.contentBind) {
          for (let i = 0; i < vm.contentOptions.length; i++) {
            if (vm.contentOptions[i].value !== vm.activeContent) {
              $(vm.contentBind).addClass('edited');
              $(vm.contentBind).find('.edited').remove();
              $(vm.contentBind).closest('con-section').find('.trash').remove();

              eddited = $('<div class="edited">' + vm.contentOptions[i].value + '</div>');
              if (event.type !== 'reset') {
                $(vm.contentBind).append(eddited);
              }
              $(vm.contentBind)
                .closest('con-section')
                .append('<div class="trash position"><span class="icon icon-bin"></span></div>');

              data_item = vm.filterData(vm.contentBind.attr('con-id'));

              if (data_item.length) {
                data_item.value = isResetAction ? '' : vm.contentOptions[i].value;
              } else {
                vm.data.push({
                  id: vm.contentBind.attr('con-id'),
                  value: isResetAction ? '' : vm.contentOptions[i].value,
                });
              }
            }
          }
        }

        data_item = vm.filterData(vm.contentId);

        if (data_item.id) {
          data_item.value = isResetAction ? '' : updateData?.value || vm.activeContent;
        } else {
          vm.data.push({
            id: vm.contentId,
            value: isResetAction ? '' : updateData?.value || vm.activeContent,
          });
        }

        vm.templateFieldsStore.updateTemplateFieldData(vm.data);

        vm.showEditTemplateModal = false;
        vm.contentBind = false;
        vm.checkSections();
        vm.recalculateElements();
        vm.contentType = '';
        vm.selectedDate = new Date();

        $('con-section').each(function () {
          $(this).removeClass('editing');
        });
        if (vm.autoFocusNextInput) {
          if (!vm.contentFilled) {
            vm.focusNextInput();
          } else {
            vm.scrollToConInput([document.querySelector('html')]);
          }
        }
      });
    },
    fetchJS() {
      const vm = this;
      vm.loaded = true;

      jQuery(document).ready(function ($) {
        setTimeout(function () {
          $('con-part').each(function () {
            const id = $(this).attr('con-id');
            let hideable = $(this).attr('hideable');
            let conPartVisible = vm.filterData(id);

            if (('true' == hideable && 'show' == conPartVisible.value) || 'true' == hideable) {
              $(this).addClass(String(conPartVisible.value));
            }

            if (
              'true' == hideable &&
              ('hide' == conPartVisible.value || 'show' == conPartVisible.value)
            ) {
              $(this).append('<div class="trash"><span class="icon icon-bin"></span></div>');
              $(this).addClass(String(conPartVisible.value));
            }
          });

          $('con-options').each(function () {
            const conOptions = $(this);
            const isNotAlternativeTextSelect = !!conOptions.attr('bind');
            const id = conOptions.attr('con-id');
            const content = vm.filterData(id);

            conOptions[0].style.cursor = 'pointer';

            if (isNotAlternativeTextSelect && content) {
              const getSelectPlaceholder = () => {
                return (
                  conOptions.attr('placeholder') ||
                  conOptions.closest('con-section').attr('label') ||
                  'Vyberte'
                );
              };
              const notSelected = $(
                `<span class="not-selected">[${getSelectPlaceholder()}]</span>`,
              );
              conOptions.append(notSelected);
            }

            isNotAlternativeTextSelect ? conOptions.addClass('edited-select') : '';

            if (content?.value) {
              let eddited = $('<div class="edited">' + content.value + '</div>');
              'counterparty' !== conOptions.attr('filling-type') ? $(this).append(eddited) : '';

              if ('position' == $(this).attr('bind')) {
                $(this)
                  .closest('con-section')
                  .append('<div class="trash position"><span class="icon icon-bin"></span></div>');
              } else if (
                'false' == $(this).closest('con-part').attr('hideable') &&
                !$(this).is('[bind]') &&
                'counterparty' !== conOptions.attr('filling-type')
              ) {
                $(this)
                  .closest('con-section')
                  .append('<div class="trash section"><span class="icon icon-bin"></span></div>');
              }

              $(this).addClass('edited');
            } else if ('counterparty' !== $(this).attr('filling-type')) {
              $(this).attr('edited', true);
              $(this).addClass('edited');
            } else {
              $(this).attr('edited', false);
              // $(this).closest('con-section').find('.trash').remove();
              $(this).removeClass('edited');
            }
          });

          $('con-text,con-textarea,con-number,con-number,con-date').each(function () {
            const input = $(this);
            const label = input.attr('label');
            const id = input.attr('con-id');
            const content = vm.filterData(id);

            if (content?.value) {
              if (0 === content.value?.trim().length) {
                input.html(label).addClass('skipped');
              } else {
                input.html(content.value);
              }

              input.addClass('edited');
            } else if ('counterparty' !== $(this).attr('filling-type')) {
              input.addClass('edited');
              input[0].style.cursor = 'inherit';

              input.html(input.html() || label);
            } else {
              input.removeClass('edited').addClass('skipped').html(label);
            }
          });

          setTimeout(function () {
            vm.checkSections();
          }, 1);
        }, 100);

        function countTopPosition(element) {
          if ($(document).find('.content-box').length) {
            const mainBox = $(document).find('.content-box').offset().top;

            let top =
              $(document).find(element).offset().top -
              mainBox -
              30 -
              $(document).find(element).height();

            if (top > $(document).find('.content-box').height()) {
              return 0;
            } else {
              return top;
            }
          }
        }

        function countSimpleTopPosition(element) {
          if ($(document).find('.content-box').length) {
            const mainBox = $(document).find('.content-box').offset().top;
            return $(document).find(element).offset().top - mainBox - 30;
          }
        }

        $(document).on('click', '.trash', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement, e) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput(vm.activeElement);
            return;
          }

          // vm.contentOptionsChoice = 'show';
          // vm.activeElementTitle = $(this).attr('name');
          // vm.activeElementDescription = $(this).attr('description');
          // vm.activeContent = '';
          vm.activeElement = $(this);
          vm.contentType = 'trash';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countSimpleTopPosition($(this));

          vm.showEditTemplateModal = true;
          vm.contentData = {};

          if ($(this).hasClass('section')) {
            $(this).closest('con-section').addClass('editing');
          } else {
            $(this).closest('con-part').addClass('editing');
          }
        });

        $(document).on('click', 'con-options.edited-select.edited > div', function () {
          const element = $(this);
          const options = element.closest('con-options');

          const this_options = options;

          vm.contentOptions = [];
          this_options.find('con-option').each(function () {
            var this_option = $(this);
            vm.contentOptions.push({
              label: this_option.attr('label'),
              value: this_option.html(),
            });
          });

          if ('position' == this_options.attr('bind')) {
            const currentId = this_options.attr('con-id');

            $("[bind='position']").each(function () {
              if ($(this).attr('con-id') != currentId) {
                vm.contentBind = $(this);
              }
            });
          }

          vm.showEditTemplateModal = true;
          vm.contentData = {};

          vm.contentOptionsChoice = '';
          vm.activeElementTitle = this_options.attr('name');
          vm.activeElementDescription = this_options.attr('description');
          vm.activeContent = '';
          vm.activeElement = this_options;
          vm.contentType = 'options';

          vm.contentData = Object.assign(
            {},
            {
              activeElement: $(this),
              activeElementTitle: vm.activeElementTitle,
              activeElementDescription: vm.activeElementDescription,
              activeElementPlaceholder: '',
              activeContentData: vm.contentOptions,
            },
          );

          vm.contentId = this_options.attr('con-id');
          vm.contentTop = countTopPosition(this_options);
          vm.activeElement[0].classList.add('selected');

          if ('position' == $(vm.activeElement).attr('bind')) {
            $("[bind='position']").each(function () {
              $(this).addClass('editing');
            });
          }
        });

        // There is no difference between usage of con-text and con-textarea, in fact variable contentType for "con-text" is set to "con-textarea", so probably dead code, but kept in place for compatibility with older templates. Up to decide what to do with it during refactoring

        $(document).on('click', 'con-text', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if ('counterparty' !== $(this).attr('filling-type')) {
            return;
          }

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.contentData = {};

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          vm.showEditTemplateModal = true;
          vm.contentData = Object.assign(
            {},
            {
              activeElement: $(this),
              activeElementTitle: vm.activeElementTitle,
              activeElementDescription: vm.activeElementDescription,
              activeElementPlaceholder: vm.activeElementPlaceholder,
              activeContentData: $(this).hasClass('edited') ? $(this).html() : '',
            },
          );

          vm.activeElement = $(this);
          vm.contentType = 'textarea';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
          vm.focusConInput(vm.activeElement[0]);
        });

        $(document).on('click', 'con-textarea', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if ('counterparty' !== $(this).attr('filling-type')) {
            return;
          }

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.contentData = {};

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          vm.showEditTemplateModal = true;
          vm.contentData = Object.assign(
            {},
            {
              activeElement: $(this),
              activeElementTitle: vm.activeElementTitle,
              activeElementDescription: vm.activeElementDescription,
              activeElementPlaceholder: vm.activeElementPlaceholder,
              activeContentData: $(this).hasClass('edited') ? $(this).html() : '',
            },
          );

          vm.activeElement = $(this);
          vm.contentType = 'textarea';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
          vm.focusConInput(vm.activeElement[0]);
        });

        $(document).on('click', 'con-date', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (e.target.innerHTML === vm.activeContent) {
            vm.focusConInput(vm.activeElement[0]);
            return;
          }

          if ('counterparty' !== $(this).attr('filling-type')) {
            return;
          }

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.contentData = {};

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          const parseDateRegex =
            /^\s*(3[01]|[12][0-9]|0?[1-9])\.\s?(1[012]|0?[1-9])\.\s?((?:19|20)\d{2})\s*$/;
          let date = null;

          let groups = e.target.innerHTML.trim().match(parseDateRegex);
          if (groups && 4 === groups.length) {
            date = new Date(`${groups[3]}/${groups[2]}/${groups[1]}`);
          } else {
            date = new Date();
          }

          vm.activeContent =
            date.getDate() + '. ' + (date.getMonth() + 1) + '. ' + date.getFullYear();
          vm.selectedDate = date;

          vm.showEditTemplateModal = true;

          vm.contentData = Object.assign(
            {},
            {
              activeElement: $(this),
              activeElementTitle: vm.activeElementTitle,
              activeElementDescription: vm.activeElementDescription,
              activeElementPlaceholder: vm.activeElementPlaceholder,
              activeContentData: {
                activeContent: vm.activeContent,
                selectedDate: vm.selectedDate,
              },
            },
          );

          vm.activeElement = $(this);
          vm.contentType = 'date';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
          vm.focusConInput(this);
        });

        $(document).on('click', 'con-number', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if ('counterparty' !== $(this).attr('filling-type')) {
            return;
          }

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          vm.activeElementTitle = $(this).attr('name');
          vm.activeElementDescription = $(this).attr('description');
          vm.activeElementPlaceholder = $(this).attr('placeholder');

          if ($(this).hasClass('edited')) {
            vm.activeContent = $(this).html();
          } else {
            vm.activeContent = '';
          }

          vm.showEditTemplateModal = true;
          vm.contentData = {};

          vm.contentData = Object.assign(
            {},
            {
              activeElement: $(this),
              activeElementTitle: vm.activeElementTitle,
              activeElementDescription: vm.activeElementDescription,
              activeElementPlaceholder: vm.activeElementPlaceholder,
              activeContentData: $(this).hasClass('edited') ? $(this).html() : '',
            },
          );

          vm.activeElement = $(this);
          vm.contentType = 'number';
          vm.contentId = $(this).attr('con-id');
          vm.contentTop = countTopPosition($(this));
          vm.activeElement[0].classList.add('selected');
          vm.focusConInput(vm.activeElement[0]);
        });

        $(document).on('click', 'con-options', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]).then(() => {
              vm.focusConInput(vm.activeElement[0]);
            });
            return;
          }

          const this_options = $(this);

          vm.contentOptions = [];
          $(this)
            .children('con-option')
            .each(function () {
              let this_option = $(this);
              vm.contentOptions.push({
                label: this_option.attr('label'),
                value: this_option.html(),
                id: this_option.attr('con-id'),
              });
            });

          $(this)
            .children('con-option')
            .each(function () {
              const $section = $(this);
              $section.find('con-text, con-textarea, con-number, con-date').each(function () {
                let nestedField = Object.assign(
                  {},
                  {
                    parentId: $section.attr('con-id'),
                    childrenId: $(this).attr('con-id'),
                  },
                );

                vm.templateFieldsStore.setNestedTemplateField(nestedField);
              });
            });

          if (!this_options.hasClass('edited')) {
            if ('position' === this_options.attr('bind')) {
              const currentId = this_options.attr('con-id');

              $("[bind='position']").each(function () {
                if ($(this).attr('con-id') !== currentId) {
                  vm.contentBind = $(this);
                }
              });
            }

            vm.showEditTemplateModal = true;
            vm.contentData = {};

            vm.contentOptionsChoice = '';
            vm.activeElementTitle = this_options.attr('name');
            vm.activeElementDescription = this_options.attr('description');
            vm.activeContent = '';
            vm.activeElement = this_options;
            vm.contentType = 'options';

            vm.contentData = Object.assign(
              {},
              {
                activeElement: $(this),
                activeElementTitle: vm.activeElementTitle,
                activeElementDescription: vm.activeElementDescription,
                activeElementPlaceholder: '',
                activeContentData: vm.contentOptions,
              },
            );

            vm.contentId = this_options.attr('con-id');
            vm.contentTop = countTopPosition(this_options);
            vm.activeElement[0].classList.add('selected');

            if ('position' == $(vm.activeElement).attr('bind')) {
              $("[bind='position']").each(function () {
                $(this).addClass('editing');
              });
            }
          }
        });

        $(document).on('click', 'con-part', function (e) {
          e.preventDefault();
          e.stopPropagation();

          if (vm.elementValueChanged(vm.activeElement) && this.autoFocusNextInput) {
            vm.$notification.warning(vm.$t('contract.form.required_save'));
            vm.scrollToConInput([e.target]);
            return;
          }

          if ($(this).hasClass('undefined') || $(this).hasClass('hide')) {
            e.preventDefault();

            if ('counterparty' !== $(this).attr('filling-type')) {
              return;
            }

            vm.contentOptionsChoice = 'show';
            vm.activeElementTitle = $(this).attr('name');
            vm.activeElementDescription = $(this).attr('description');

            vm.showEditTemplateModal = true;
            vm.contentData = {};

            vm.contentData = Object.assign(
              {},
              {
                activeElement: $(this),
                activeElementTitle: vm.activeElementTitle,
                activeElementDescription: vm.activeElementDescription,
                activeElementPlaceholder: vm.activeElementPlaceholder,
                activeContentData: vm.contentOptionsChoice,
              },
            );

            vm.activeContent = '';
            vm.activeElement = $(this);
            vm.contentType = 'part';
            vm.contentId = $(this).attr('con-id');
            vm.contentTop = countSimpleTopPosition($(this));
            vm.activeElement[0].classList.add('selected');

            $(this).addClass('editing');
          }
        });
      });
    },
    filterData(searchId) {
      if (this.data.length) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id == searchId) {
            return this.data[i];
          }
        }
      }

      return false;
    },
    checkSections() {
      const vm = this;

      jQuery(document).ready(function ($) {
        $(document)
          .find('con-part')
          .each(function () {
            $(document)
              .find('con-section con-identity')
              .each(function () {
                let ready = false;
                const $section = $(this).closest('con-section');

                $section.find('con-identity').each(function () {
                  $section.addClass('hasIdentity');
                });

                $section.find('con-options').each(function () {
                  $section.addClass('hasOptions');

                  const $conOptions = $(this);

                  if ($conOptions.hasClass('edited')) {
                    ready = true;
                  } else {
                    ready = false;
                  }
                });

                if (ready) {
                  $section.addClass('completed');
                } else {
                  $section.removeClass('completed');
                }
              });

            const $conPart = $(this);
            const conPartId = $conPart.attr('con-id');
            const conHideable = $conPart.attr('hideable');
            const conPartVisible = vm.filterData(conPartId);

            if ($conPart.hasClass('completed') || $conPart.hasClass('undefined')) {
              $conPart.find('con-section').each(function () {
                if ($(this).not('.completed')) {
                  $(this).addClass('completed');
                }
              });
            }

            if (
              'show' == conPartVisible.value ||
              'false' == conHideable ||
              $conPart.hasClass('completed')
            ) {
              $conPart.find('con-section').each(function () {
                let ready = true;
                const $section = $(this);

                $section
                  .find('con-textarea, con-text, con-input, con-number, con-date')
                  .each(function () {
                    if (!$(this).closest('option').length && $(this).is(':visible')) {
                      if ($(this).hasClass('edited')) {
                        ready &= true;
                      } else {
                        ready &= false;
                      }
                    }
                  });

                $section.find('con-identity').each(function () {
                  $section.addClass('hasIdentity');
                });

                let allOptions = [...$section.find('con-options')].reverse();

                allOptions = allOptions
                  .filter((item, index, array) => {
                    return (
                      index ===
                      array.findIndex(
                        (comparedItem) =>
                          comparedItem.attributes['con-id'].value ===
                          item.attributes['con-id'].value,
                      )
                    );
                  })
                  .reverse();

                const filteredOptions = ((nodesInArray) => {
                  const codeFragment = document.createDocumentFragment();
                  nodesInArray.forEach((item) => {
                    codeFragment.appendChild(item.cloneNode());
                  });

                  return codeFragment.childNodes;
                })(allOptions);

                $.each(filteredOptions, function () {
                  $section.addClass('hasOptions');

                  var $conOptions = $(this);

                  if ($conOptions.hasClass('edited')) {
                    ready &= true;
                  } else {
                    ready &= false;
                  }

                  $conOptions
                    .find('div.edited')
                    .find('con-textarea, con-text, con-input, con-number, con-date')
                    .each(function () {
                      if ($(this).hasClass('edited')) {
                        ready &= true;
                      } else {
                        ready &= false;
                      }
                    });
                });

                if (ready) {
                  $section.addClass('completed');
                } else {
                  $section.removeClass('completed');
                }
              });
            }
          });

        vm.recalculateElements(true);
      });
    },
    elementValueChanged(activeElement, trashEvent = null) {
      if (!activeElement) return false;
      if (this.isActiveElementOptional()) return false;
      if (trashEvent) {
        return true;
      }

      if ('con-options' === activeElement[0].localName) return false;
      if ('div' === activeElement[0].localName && null === activeElement[0].getAttribute('con-id'))
        return false;

      const elementConId = activeElement[0].getAttribute('con-id');
      const elementInnerHTML = activeElement[0].innerHTML;
      const elementDefaultLabel = activeElement[0].getAttribute('label');
      const elementData = this.data.filter((el) => el.id === elementConId)[0];

      let changed = false;

      if (elementData) {
        changed = elementData.value !== elementInnerHTML;
      } else {
        changed = elementInnerHTML !== elementDefaultLabel;
      }

      return changed;
    },
    recalculateElements(checkShowFinish = false) {
      const vm = this;
      let total = 0;
      let done = 0;
      let totalWithoutOptional = 0;
      let doneWithoutOptional = 0;

      let bindedPosition = false;
      let visibleFields = [];

      $(document)
        .find('con-text,con-textarea,con-number,con-date,con-options,con-identity')
        .each(function () {
          const input = $(this);
          const id = input.attr('con-id').toString();

          let visibleField = vm.getTemplateFields.find((field) => field.id == id);

          visibleField ? visibleFields.push(visibleField) : null;
        });
      if (!visibleFields.length) {
        return;
      }

      for (let templateField of this.getTemplateFields) {
        const visible = visibleFields.find((visibleField) => visibleField.id === templateField.id);

        if (!visible) {
          this.templateFieldsStore.setHiddenOptionField(templateField?.id);
        }
      }

      $(document)
        .find('con-part')
        .each(function () {
          const $conPart = $(this);
          const conPartId = $conPart.attr('con-id');
          const conHideable = $conPart.attr('hideable');

          $(document)
            .find('con-section con-identity')
            .each(function () {
              const $section = $(this).closest('con-section');

              if (!bindedPosition) {
                bindedPosition = true;

                if ($section.hasClass('completed')) {
                  total += 1;
                  done += 1;
                } else {
                  total += 1;
                  // done += 1;
                }

                if (!$section.is('[optional=true]')) {
                  if ($section.hasClass('completed')) {
                    totalWithoutOptional += 1;
                    doneWithoutOptional += 1;
                  } else {
                    totalWithoutOptional += 1;
                    // done += 1;
                  }
                }
              }
            });

          let conPartVisible = vm.filterData(conPartId);

          if ('show' == conPartVisible.value || 'false' == conHideable) {
            $conPart.find('con-section').each(function () {
              const $section = $(this);

              if ($section.hasClass('completed')) {
                total += 1;
                done += 1;
                if (!$section.is('[optional=true]')) {
                  totalWithoutOptional += 1;
                  doneWithoutOptional += 1;
                }
              } else {
                $section.find('con-text, con-textarea, con-number, con-date').each(function () {
                  let conOptions = $(this).closest('con-option').closest('con-options');

                  if (!conOptions.length) {
                    total += 1;

                    if ($(this).hasClass('edited')) {
                      done += 1;
                    }

                    if (!$(this).is('[optional=true]')) {
                      totalWithoutOptional += 1;
                    }
                    if (!$(this).is('[optional=true]') && $(this).hasClass('edited')) {
                      doneWithoutOptional += 1;
                    }
                  }

                  if (conOptions.length && conOptions.hasClass('edited')) {
                    total += 1;

                    if ($(this).hasClass('edited')) {
                      done += 1;
                    }

                    if (!conOptions.is('[optional=true]')) {
                      totalWithoutOptional += 1;
                      if ($(this).hasClass('edited') && $(this).is('[optional=true]')) {
                        doneWithoutOptional += 1;
                      }
                    }
                  }
                });

                $section.find('con-options').each(function () {
                  let conOptions = $(this);

                  if (conOptions.hasClass('edited')) {
                    total += 1;
                    done += 1;
                    if (!conOptions.is('[optional=true]')) {
                      totalWithoutOptional += 1;
                      doneWithoutOptional += 1;
                    }
                  } else {
                    total += 1;
                    if (!conOptions.is('[optional=true]')) {
                      totalWithoutOptional += 1;
                    }
                  }
                });
              }
            });
          } else if (!conPartVisible) {
            total += 1;
            done += 0;

            if (!$conPart.is('[optional=true]')) {
              totalWithoutOptional += 1;
              doneWithoutOptional += 0;
            }
          }
        });

      if (total == done) {
        window.dataLayer.push({ event: 'contract_proposal_text_completed' });
      }

      vm.recalculatePart();

      vm.total = 0 === total ? 1 : total;
      vm.totalWithoutOptional = totalWithoutOptional;
      vm.doneWithoutOptional = doneWithoutOptional;
      vm.done = done;

      if (checkShowFinish && vm.total === vm.done && 0 === vm.total) vm.showFinish = true;
    },
    recalculatePart() {
      const vm = this;

      jQuery(document).ready(function ($) {
        let titleIndex = 0;

        $(document)
          .find('con-part')
          .each(function () {
            const $conPart = $(this);
            const conPartId = $conPart.attr('con-id');
            const hideable = $conPart.attr('hideable');
            const conPartCounterparty = 'counterparty' === $conPart.attr('filling-type');
            let conPartVisible = vm.filterData(conPartId);

            if (!conPartVisible && !conPartCounterparty) {
              $conPart.addClass('completed');
              $conPart.removeClass('undefined');
            }

            if ('show' == conPartVisible.value || 'false' == hideable || !hideable) {
              titleIndex = titleIndex + 1;
              $conPart.find('con-no[con-part-id="' + conPartId + '"]').each(function () {
                $(this).text(titleIndex);
              });
            }
          });
      });
    },
    focusConInput(element) {
      this.$nextTick(() => {
        setTimeout(() => {
          switch (true) {
            case 'con-textarea' === element.localName:
              $('.ProseMirror').focus();
              break;
            case 'con-date' === element.localName:
              this.$nextTick(() => {
                if (this.$refs.datePickerRef && !this.$refs.datePickerRef.isShown) {
                  this.$refs.datePickerRef.$data.date = this.selectedDate;
                  $('.datetime-picker__button').click();
                }
              });
              break;
            case 'con-number' === element.localName:
              $('input.input__data[type=number]').focus();
              break;
          }
        }, 200);
      });
    },
    isActiveElementRequired() {
      return !this.isActiveElementOptional();
    },
    isActiveElementOptional() {
      const optional = this.activeElement[0].attributes.getNamedItem('optional');
      return optional && 'true' === optional.nodeValue;
    },
    changePartRadioValue(newValue) {
      $(this.activeElement).removeClass('undefined');
      $(this.activeElement).removeClass('show');
      $(this.activeElement).removeClass('hide');
      $(this.activeElement).addClass(newValue);
      this.contentOptionsChoice = newValue;
    },
    resetContentType(emptyString) {
      this.contentType = emptyString;
    },
    savePartContentType() {
      const vm = this;

      jQuery(document).ready(function ($) {
        $(vm.activeElement).append('<div class="trash"><span class="icon icon-bin"></span></div>');

        $(vm.activeElement).removeClass('editing');
        $(vm.activeElement).removeClass('undefined');
        $(vm.activeElement).removeClass('show');
        $(vm.activeElement).removeClass('hide');
        $(vm.activeElement).addClass(vm.contentOptionsChoice);

        vm.saveToData(vm.contentId, vm.contentOptionsChoice);
        vm.checkSections();
        vm.recalculateElements();
        vm.contentType = '';
      });
    },
    deletePart() {
      const vm = this;
      const _that = $(this.activeElement);
      const $conPart = $(_that).closest('con-part');
      let $conSection;
      let conSectionId;

      if (_that.hasClass('section')) {
        $conSection = _that.closest('con-section');

        $conSection.removeClass('completed');
        conSectionId = $conSection.find('con-options').attr('con-id');
        vm.deleteFromData(conSectionId);

        $conSection.find('con-options').removeClass('edited');
        $conSection.find('con-options').removeClass('completed');
        $conSection.find('div.edited').remove();
        $conSection.find('.trash').remove();

        $conSection
          .find('con-options')
          .children('con-option')
          .each(function () {
            const $section = $(this);
            $section.find('con-text, con-textarea, con-number, con-date').each(function () {
              vm.templateFieldsStore.resetHiddenFields($(this).attr('con-id'));
              $(this).html($(this).attr('label'));
            });
          });

        $conSection.removeClass('editing');
      } else {
        $conSection = $conPart.find('con-section');

        $conPart.find('.trash').remove();
        $conPart.removeClass('editing');
        $conPart.removeClass('show');
        $conPart.removeClass('hide');
        $conPart.addClass('undefined');

        $conSection.removeClass('completed');

        vm.deleteFromData($conPart.attr('con-id'));

        conSectionId = $conSection.find('con-options').attr('con-id');

        vm.deleteFromData(conSectionId);

        $conSection.find('con-options').removeClass('edited');
        $conSection.find('con-options').removeClass('completed');
        $conSection.find('div.edited').remove();
        $conSection.find('.trash').remove();

        if ($conSection.hasClass('hasIdentity') || _that.hasClass('position')) {
          $(document)
            .find("[bind='position']")
            .each(function () {
              var _that = $(this);

              $(this).closest('con-section').removeClass('editing');

              var binded = $(this);
              var bindedId = binded.attr('con-id');

              vm.deleteFromData(bindedId);

              _that.removeClass('edited');
              _that.removeClass('completed');
              _that.find('div.edited').remove();
              _that.closest('con-section').find('.trash').remove();
              _that.closest('con-section').removeClass('completed');
            });
        }
      }

      this.showEditTemplateModal = false;

      vm.recalculateElements();
      vm.contentType = '';
      vm.focusNextInput();
    },
    deleteFromData(id) {
      let deleted = this.data.findIndex((data) => data.id === id);

      if (-1 !== deleted) {
        this.data.splice(deleted, 1);
      }
    },
    scrollToConInput(target) {
      return new Promise((resolve) => {
        const scrollOffset = 300;
        let scrollTo =
          document.querySelector('html, body').scrollTop +
          target[0].getBoundingClientRect().top -
          scrollOffset;
        let pageEnd =
          document.querySelector('html').offsetHeight - document.querySelector('html').clientHeight;

        document.querySelector('html').scroll({
          top: scrollTo,
          behavior: 'smooth',
        });

        const checkIfScrollToIsFinished = setInterval(() => {
          if (
            document.querySelector('html').scrollTop === scrollTo ||
            document.querySelector('html').scrollTop === pageEnd
          ) {
            clearInterval(checkIfScrollToIsFinished);
            resolve('ok');
          }
        }, 25);
      });
    },
    focusNextInput() {
      if (this.contentFilled) {
        return;
      }

      let target = $(
        'con-section:not(.completed):not(.editing):not(.hasOptions):not([optional=true]), con-section.hasOptions > con-options > div.edited, con-section.hasOptions > con-options:not([optional=true]), con-part > con-section:not(.completed):not(.editing):not(.hasOptions):not([optional=true]), #contract-template-content div',
      );
      if (target.length) {
        let elements = target.find(
          'con-option:visible, con-textarea:visible:not(.edited):not(.selected):not([optional=true]), con-date:visible:not(.edited):not(.selected):not([optional=true]), con-number:visible:not(.edited):not(.selected):not([optional=true]), con-part[hideable="true"]',
        );
        if (
          elements.length &&
          $(elements[0]).is('con-option') &&
          $(elements[0]).parent().is('[optional=true]')
        ) {
          const firstNonOptionalIndex = [...elements].findIndex((element) => {
            return !($(element).is('con-option') && $(element).parent().is('[optional=true]'));
          });

          if (-1 === firstNonOptionalIndex) {
            elements = [undefined];
          } else {
            elements = elements.slice(firstNonOptionalIndex);
          }
        }

        if (elements[0] !== undefined) {
          this.scrollToConInput(elements).then(() => {
            elements[0].click();

            this.focusConInput(elements[0]);
          });
        }
      }
    },
    toggleAutoFocusNextInput() {
      this.autoFocusNextInput = !this.autoFocusNextInput;

      if (this.autoFocusNextInput) {
        this.focusNextInput();
      }
    },
    async prefillData() {
      const status = parseInt(await ContractService.prefill(this.contract.id));

      if (200 === status) {
        return (() => {
          this.$notification.success(this.$t('general.filled'));
          this.$router.replace({ query: { registry: 'true' } });

          setTimeout(() => {
            this.$router.replace({ query: {} });
          }, 10);
        })();
      }

      if (400 === status) {
        return (() => {
          this.$notification.error(this.$t('contract.prefill.no_data'));
        })();
      }

      this.$notification.error(this.$t('general.data_not_loaded'));
    },
  },
});
</script>

<style lang="scss">
.document-view {
  position: relative;
  z-index: 0;

  &__template {
    padding: 1rem 3rem;

    h2 {
      text-align: center !important;
      margin: 30px 0 0 0;
    }

    @include display(960px) {
      padding: 0.5rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.content {
  text-align: left;
}

.content-box__data {
  padding: 0;
}

#contract-template-content__edit {
  text-align: left !important;

  color: #4a4a4a;

  :is(con-textarea, con-option, con-options, con-number, con-date):not(
      [filling-type='counterparty']
    ) {
    cursor: inherit !important;
  }

  ::v-deep con-section {
    margin: 20px 0;
  }

  @include md {
    padding: 0 15px;
  }

  p {
    margin-top: 20px;
  }

  p.centered {
    text-align: center;
  }

  h2 {
    text-align: center !important;
    margin: 30px 0 0 0;
  }

  h1 {
    display: none;
  }

  .table {
    width: 100%;
    margin-top: 30px;
    border-spacing: 0;
    font-size: 15px;
    color: #4a4a4a;
    letter-spacing: -0.03px;
    line-height: 1.7;
    font-weight: 300;

    tr {
      &:last-of-type {
        td {
          border-bottom-color: #000;
        }
      }

      td {
        border: 1px solid #000;
        border-right-color: transparent;
        border-bottom-color: transparent;
        padding: 5px 10px;

        &:last-of-type {
          border-right-color: #000;
        }
      }
    }
  }

  .document-view__template con-section {
    margin: 20px 0 !important;
  }
}
</style>
