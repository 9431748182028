<template>
  <v-dialog :value="value" max-width="650px">
    <v-card class="px-6 py-5" style="background-color: #f2f6fc">
      <v-toolbar dense flat class="pt-0 mt-0">
        <v-spacer></v-spacer>
        <v-btn type="reset" icon @click="closeOpenedContentType">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        v-if="contentType === 'options'"
        class="d-flex flex-column align-center justify-center text-center px-3 py-4"
        style="background-color: white"
      >
        <form @submit.prevent="saveContentType" @reset.prevent="saveContentType">
          <div>
            <span class="controll-title">{{ contentData.activeElementTitle }}</span>
          </div>

          <div v-if="contentData.activeElementDescription">
            <span class="controll-desc">{{ contentData.activeElementDescription }}</span>
          </div>

          <div class="my-3 ml-4" v-if="contentOptions && contentOptions.length">
            <Radio
              v-for="(option, index) in contentOptions"
              :key="index"
              :id="option.id"
              :value="contentOptionsChoice"
              :labelValue="option.value"
              :label="option.label"
              @change="changeRadioValue"
              @updateId="setOptionId"
            />
          </div>

          <div class="content-box__controll__buttons">
            <Button buttonType="submit" :disabled="activeContent == ''" type="primary-small">{{
              $t('general.save')
            }}</Button>
          </div>

          <div class="content-box__controll__buttons" v-if="isActiveElementOptional()">
            <Button buttonType="reset" type="primary-small">{{ $t('contract.skip') }}</Button>
          </div>
        </form>
      </div>

      <div
        v-if="contentType == 'textarea'"
        class="d-flex flex-column align-center justify-center text-center px-3 py-4"
        style="background-color: white"
      >
        <form
          @submit.prevent="saveContentType"
          @reset.prevent="saveContentType"
          style="width: 100%"
        >
          <div>
            <span class="controll-title">{{ getContentData.activeElementTitle }}</span>
          </div>

          <div v-if="getContentData.activeElementDescription">
            <span class="controll-desc">{{ getContentData.activeElementDescription }}</span>
          </div>

          <div class="wysiwyg-editor" style="width: 100%">
            <editor-menu-bar
              class="wysiwyg-editor-menu"
              :editor="activeContent"
              v-slot="{ commands, isActive }"
            >
              <div>
                <button
                  :class="{ 'is-active': isActive.bold() }"
                  @click.prevent="commands.bold"
                  :style="{ fontWeight: 'bold' }"
                >
                  B
                </button>
                <button
                  :class="{ 'is-active': isActive.italic() }"
                  @click.prevent="commands.italic"
                  :style="{ fontStyle: 'italic' }"
                >
                  I
                </button>
                <button
                  :class="{ 'is-active': isActive.underline() }"
                  @click.prevent="commands.underline"
                  :style="{ textDecoration: 'underline' }"
                >
                  U
                </button>
              </div>
            </editor-menu-bar>

            <editor-content
              class="wysiwyg-editor-content"
              :placeholder="getContentData.activeElementPlaceholder"
              :editor="activeContent"
            />
          </div>

          <div class="content-box__controll__buttons">
            <Button buttonType="submit" type="primary-small">{{ $t('general.save') }}</Button>
          </div>

          <div class="content-box__controll__buttons" v-if="isActiveElementOptional()">
            <Button buttonType="reset" type="primary-small">{{ $t('contract.skip') }}</Button>
          </div>

          <div>
            <small class="controll-desc">{{ $t('contract.press_alt_enter') }}</small>
          </div>
        </form>
      </div>

      <div
        v-if="contentType == 'date'"
        class="d-flex flex-column align-center justify-center text-center px-3 py-4"
        style="background-color: white"
      >
        <form
          @submit.prevent="saveContentType"
          @reset.prevent="saveContentType"
          style="width: 100%"
        >
          <div>
            <span class="controll-title">{{ contentData.activeElementTitle }}</span>
          </div>

          <div v-if="contentData.activeElementDescription">
            <span class="controll-desc">{{ contentData.activeElementDescription }}</span>
          </div>

          <div style="width: 100%">
            <SDatePicker
              v-model="choiceDate"
              :label="$t('contract.date')"
              :placeholder="contentData.activeElementPlaceholder"
            />
          </div>

          <div class="content-box__controll__buttons">
            <Button buttonType="submit" type="primary-small">{{ $t('general.save') }}</Button>
          </div>

          <div class="content-box__controll__buttons" v-if="isActiveElementOptional()">
            <Button buttonType="reset" type="primary-small">{{ $t('contract.skip') }}</Button>
          </div>
        </form>
      </div>

      <div
        v-if="contentType == 'number'"
        class="d-flex flex-column align-center justify-center text-center px-3 py-4"
        style="background-color: white"
      >
        <form @submit.prevent="saveContentType" @reset.prevent="saveContentType">
          <div>
            <span class="controll-title">{{ contentData.activeElementTitle }}</span>
          </div>

          <div v-if="contentData.activeElementDescription">
            <span class="controll-desc">{{ contentData.activeElementDescription }}</span>
          </div>

          <div>
            <Input
              type="number"
              :placeholder="contentData.activeElementPlaceholder"
              v-model="content.value"
            />
          </div>

          <div class="content-box__controll__buttons">
            <Button buttonType="submit" type="primary-small">{{ $t('general.save') }}</Button>
          </div>

          <div class="content-box__controll__buttons" v-if="isActiveElementOptional()">
            <Button buttonType="reset" type="primary-small">{{ $t('contract.skip') }}</Button>
          </div>
        </form>
      </div>

      <div
        v-if="contentType == 'part'"
        class="d-flex flex-column align-center justify-center text-center px-3 py-4"
        style="background-color: white"
      >
        <form @submit.prevent="savePartContentType()">
          <div>
            <span class="controll-title">{{
              $t('contract.choose_if_article_should_be_shown')
            }}</span>
          </div>

          <div v-if="contentData.activeElementDescription">
            <span class="controll-desc">{{ contentData.activeElementDescription }}</span>
          </div>

          <div class="my-3 ml-4">
            <Radio
              :value="contentOptionsChoice"
              labelValue="show"
              :label="$t('general.show')"
              @change="changePartRadioValue"
            />
          </div>

          <div>
            <Radio
              :value="contentOptionsChoice"
              labelValue="hide"
              :label="$t('contract.hide')"
              @change="changePartRadioValue"
            />
          </div>

          <div class="content-box__controll__buttons">
            <Button buttonType="submit" type="primary-small">{{ $t('general.save') }}</Button>
          </div>

          <div class="content-box__controll__buttons" v-if="isActiveElementOptional()">
            <Button buttonType="reset" type="primary-small">{{ $t('contract.skip') }}</Button>
          </div>
        </form>
      </div>

      <div
        v-if="contentType === 'trash'"
        class="d-flex flex-column align-center justify-center text-center px-3 py-4"
        style="background-color: white"
      >
        <div>
          <span class="controll-title">{{ $t('contract.remove_block') }}</span>
        </div>

        <div>
          <span class="controll-desc">{{ $t('contract.really_want_remove_block') }}</span>
        </div>

        <div class="content-box__controll__buttons">
          <Button @click="deletePart" buttonType="submit" type="primary-small">{{
            $t('general.delete')
          }}</Button>
        </div>

        <div class="content-box__controll__buttons">
          <Button @click="closeOpenedContentType" buttonType="submit" type="primary-small">{{
            $t('general.cancel')
          }}</Button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { Bold, HardBreak, Italic, Placeholder, Underline } from 'tiptap-extensions';
import moment from 'moment';
import SDatePicker from '@/common/components/SDatePicker';

export default defineComponent({
  name: 'EditTemplateModal',
  components: { EditorContent, EditorMenuBar, SDatePicker },
  data() {
    return {
      activeContent: '',
      content: {},
      choiceDate: new Date().toISOString().substr(0, 10),
      selectedDate: new Date().toISOString().substr(0, 10),
      contentOptions: [],
      contentOptionsChoice: '',
    };
  },
  props: {
    value: Boolean,
    contentType: {
      type: String,
      required: true,
    },
    contentData: {
      type: Object,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    getContentData() {
      return this.contentData;
    },
  },
  watch: {
    contentType: {
      deep: true,
      handler(newValue) {
        if ('textarea' === newValue) {
          if (this.activeContent instanceof Editor) {
            this.activeContent.destroy();
            this.activeContent = null;
          }

          this.content.value = this.contentData?.activeContentData || '';

          return (this.activeContent = new Editor({
            content: this.contentData?.activeContentData || '',
            extensions: [
              new HardBreak(),
              new Bold(),
              new Italic(),
              new Underline(),
              new Placeholder({
                emptyNodeClass: 'is-empty',
                emptyNodeText: this.contentData?.activeElementPlaceholder || 'vložte text',
                showOnlyWhenEditable: true,
              }),
            ],
            onUpdate: (editor) => {
              let htmlContent = editor.getHTML();
              this.content = Object.assign(
                {},
                {
                  value: htmlContent.replace('<p>', '').replace('</p>', ''),
                },
              );
            },
          }));
        }

        if ('number' === newValue) {
          this.content = {};

          return Object.assign(this.content, {
            value: this.contentData?.activeContentData,
          });
        }

        if ('part' === newValue) {
          this.content = {};

          return (this.contentOptionsChoice = this.contentData?.activeContentData);
        }

        if ('options' === newValue) {
          this.content = {};

          return (this.contentOptions = this.contentData?.activeContentData);
        }

        if ('date' === newValue) {
          this.content = {};

          Object.assign(this.content, {
            value: this.contentData?.activeContentData?.activeContent,
            selectedDate: this.contentData?.activeContentData?.selectedDate,
            choiceDate: this.choiceDate,
          });
        }
      },
    },
    choiceDate(date) {
      const currentDate = new Date(date);

      this.activeContent =
        currentDate.getDate() +
        '. ' +
        (currentDate.getMonth() + 1) +
        '. ' +
        currentDate.getFullYear();
      this.selectedDate = moment(currentDate).toDate();

      this.$nextTick(() => {
        Object.assign(this.content, {
          value: this.activeContent,
          selectedDate: this.selectedDate,
          choiceDate: this.choiceDate,
        });
      });
    },
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      // ALT + Enter for quick save con-textarea
      if (
        this.activeContent &&
        this.activeContent instanceof Editor &&
        13 === event.keyCode &&
        event.altKey
      ) {
        this.saveContentType();
      }
    });
  },
  methods: {
    closeOpenedContentType() {
      const elementsInEditingMode = document.getElementsByClassName('editing');

      for (let element of elementsInEditingMode) {
        element.classList.remove('editing');
      }

      this.$emit('resetContentType', '');
      this.showDialog = false;
    },
    saveContentType(e) {
      this.$emit(
        'saveContentType',
        e,
        this.content,
        //this.content.length > 0 ? this.content : 'label',
        this.contentType,
      );

      this.content = {};
    },
    deletePart() {
      this.$emit('deletePart');
    },
    isActiveElementOptional() {
      const optional = this.contentData?.activeElement[0]?.attributes.getNamedItem('optional');
      return optional && 'true' === optional.nodeValue;
    },
    savePartContentType() {
      this.$emit('savePartContentType');
    },
    changeRadioValue(newValue) {
      this.contentOptionsChoice = newValue;
      this.activeContent = newValue;
      Object.assign(this.content, {
        value: newValue,
      });
      this.contentOptionsChoice = '';
    },
    changePartRadioValue(newValue) {
      this.contentOptionsChoice = newValue;
      this.$emit('changePartRadioValue', newValue);
    },
    setOptionId(id) {
      this.content.optionId = id;
    },
  },
});
</script>

<style lang="scss" scoped></style>
