<template>
  <v-tooltip :disabled="!isTooltipDisabled" right>
    <template v-slot:activator="{ on, attrs }">
      <v-layout
        v-bind="attrs"
        v-on="on"
        align-center
        justify-center
        fill-height
        ma-0
        class="flex-column"
      >
        <v-layout column align-center justify-center>
          <div ref="tooltip" class="participant-name font-weight-medium text-center text-truncate">
            {{ participantName }}
          </div>
        </v-layout>
        <v-btn
          v-if="canShowEditPartiesButton"
          plain
          x-small
          color="primary"
          style="position: absolute"
          :style="`${isButtonTextVisible ? 'bottom: 3%' : 'left: -2rem'}`"
          @click="$emit('edit-signature')"
        >
          <span v-if="isVariablePosition && isProposer">
            <v-icon :x-small="isButtonTextVisible" small class="mr-1"> mdi-account-plus </v-icon>
            <span v-if="isButtonTextVisible">
              {{ $t('contract.create.add_signer') }}
            </span>
          </span>

          <span v-else-if="!isDelegationByProposerDisabled">
            <v-icon :x-small="isButtonTextVisible" small class="mr-1">mdi-account-convert</v-icon>
            <span v-if="isButtonTextVisible">
              {{ $t('contract.replace.signer') }}
            </span>
          </span>
        </v-btn>
      </v-layout>
    </template>
    <span>{{ participantName }} </span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';

export default {
  name: 'SignByOtherPreview',
  props: {
    participantName: {
      type: String,
      default: '',
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
    isContractOwner: {
      type: Boolean,
      default: false,
    },
    isVariablePosition: {
      type: Boolean,
      default: false,
    },
    isProposer: {
      type: Boolean,
      default: false,
    },
    relativeWidth: {
      type: Number,
    },
  },
  data: () => {
    return {
      disabledTooltip: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    canShowEditPartiesButton() {
      return 'documentsDetail' === this.$route.name && (this.isProposer || this.isContractOwner);
    },
    isTooltipDisabled() {
      return this.disabledTooltip;
    },
    isButtonTextVisible() {
      return !this.$vuetify.breakpoint.xs && this.relativeWidth > 17;
    },
    isDelegationByProposerDisabled() {
      return hasFeatureFlag(this.contract, 'proposerSignatureDelegationDisabled');
    },
  },
  mounted() {
    this.disableTooltip();
  },
  methods: {
    disableTooltip() {
      this.$nextTick(() => {
        const el = this.$refs?.tooltip || null;
        if (el) {
          this.disabledTooltip = el.scrollWidth > el.clientWidth;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.participant-name {
  width: 100%;
  font-size: clamp(0.2rem, 2vw, 1.2rem);
}

.v-tooltip__content {
  background-color: #fffad1 !important;
  border: 1px solid #e8dbb6 !important;
  border-radius: 100px !important;
  color: #8b572a !important;
  padding: 0.7rem !important;
}
</style>
