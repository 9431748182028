<template>
  <v-row
    class="pl-3"
    align="center"
    justify="start"
    no-gutters
    style="border-bottom: 1px solid #d8d8d8"
  >
    <v-col class="px-2 py-3">
      <v-checkbox
        :value="autoFocusNextInput"
        @change="$emit('update:autoFocusNextInput', $event)"
        class="mt-0"
        hide-details
      >
        <template v-slot:label>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ $t('contract.automatic_completion') }}
              </span>
            </template>

            {{ $t('contract.automatic_completion_description') }}
          </v-tooltip>
        </template>
      </v-checkbox>
    </v-col>

    <v-col v-if="isKatastrEnabled" cols="auto" class="px-2 py-3">
      <v-hover v-slot="{ hover }">
        <v-btn
          color="primary"
          :class="{ 'text-decoration-underline': hover }"
          text
          @click="prefillData"
          >{{ $t('contract.prefill.from_katastr') }}</v-btn
        >
      </v-hover>
    </v-col>

    <v-col v-if="isPrefillFromContactsEnabled" cols="auto" class="px-2 py-3">
      <v-hover v-slot="{ hover }">
        <v-btn
          color="primary"
          :class="{ 'text-decoration-underline': hover }"
          text
          @click="prefillData"
          >{{ $t('contract.prefill.from_contacts') }}</v-btn
        >
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'TemplateAutocomplete',
  props: {
    autoFocusNextInput: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    isKatastrEnabled() {
      return FeatureFlags.isKatastrEnabled(this.contract);
    },
    isPrefillFromContactsEnabled() {
      return FeatureFlags.isPrefillFromContactsEnabled(this.contract);
    },
  },
  methods: {
    prefillData() {
      this.$emit('prefillData');
    },
  },
});
</script>

<style scoped></style>
