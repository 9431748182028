<template>
  <div class="contract-signature" :class="{ 'contract-signature--light': customColor }">
    <DetailSignature
      v-for="party in indexedParties"
      :key="party.id"
      :sign-type="party.signType"
      :sign-index="party.signIndex"
      :party-order="party.partyOrder"
      :signIdentity="party.signIdentity"
      :customColor="customColor"
      :disabled="isDisabled"
      @sign="sign"
    />
  </div>
</template>

<script>
import { setupAgreeCheckbox } from '@/helpers/agreeValidatorRenderer.js';
import { ContractHelper } from '@/helpers/ContractHelper';
import DetailSignature from '@/components/contract/DetailSignature.vue';
import { computed, defineComponent } from 'vue';
import { useTemplateFieldsStore } from '@/features/contract/stores/templateFieldsStore';

export default defineComponent({
  name: 'DetailSignatures',
  components: {
    DetailSignature,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
    sign: {
      type: Function,
      required: true,
    },
    customColor: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checkboxValidatorNotChecked: false,
      currentSignIdentity: {},
    };
  },
  setup() {
    const templateFieldsStore = useTemplateFieldsStore();

    const isTemplateCompleted = computed(() => templateFieldsStore.isTemplateCompleted);

    return {
      isTemplateCompleted,
    };
  },
  computed: {
    indexedParties() {
      const parties = [];
      let proposers = 1;
      let counterparties = 1;

      this.contract.sign_identities.forEach((signIdentity) => {
        if (!ContractHelper.isSignatureRequiredForCurrentUser(signIdentity)) {
          return;
        }

        parties.push({
          id: +new Date() + parties.length,
          signType: signIdentity.is_proposer ? 'proposer' : 'signer',
          signIndex: signIdentity.is_proposer ? proposers++ : counterparties++,
          partyOrder: signIdentity.party_order,
          signIdentity: signIdentity,
        });
      });

      return parties;
    },
    size() {
      const sizes = {
        xs: 'x-small',
        sm: 'small',
        lg: 'large',
        xl: 'x-large',
      }[this.$vuetify.breakpoint.name];

      return sizes ? { [sizes]: true } : {};
    },
    isCurrentCounterpartyUnsigned() {
      return (
        ContractHelper.isUnsigned(this.contract) &&
        this.currentSignIdentity &&
        !this.currentSignIdentity.is_proposer
      );
    },
    isSignedByCounterparty() {
      return this.contract?.sign_identities.some(
        (signIdentity) => !signIdentity.is_proposer && signIdentity.is_signed,
      );
    },
    isDisabled() {
      if ('form' === this.contract?.type) {
        return (
          (!this.isTemplateCompleted || !this.isSignedByCounterparty) &&
          this.checkboxValidatorNotChecked
        );
      }

      return this.checkboxValidatorNotChecked;
    },
  },
  mounted() {
    this.currentSignIdentity = ContractHelper.getCurrentSignIdentity(this.contract);

    if (this.isCurrentCounterpartyUnsigned) {
      const checkboxWrapper = document.querySelector('con-agree');

      if (checkboxWrapper) {
        this.checkboxValidatorNotChecked = true;

        setupAgreeCheckbox(checkboxWrapper, () => {
          this.checkboxValidatorNotChecked = !this.checkboxValidatorNotChecked;
          return !this.checkboxValidatorNotChecked;
        });
      }
    }
  },
  methods: {
    render(contractColor) {
      console.log(contractColor || null);
    },
  },
});
</script>

<style lang="scss" scoped></style>
