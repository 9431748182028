<template>
  <v-card class="pa-md-5 mx-auto" max-width="70rem" elevation="0">
    <div v-if="isLoading" style="min-height: 20rem">
      <Loader />
    </div>

    <template v-else>
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel v-for="auth in auths" :key="auth.docs_person_id">
          <v-expansion-panel-header>
            <v-row align="center" justify="start">
              <v-col
                v-if="isSignpostConfirmed(auth.auths) && isAuthDone(auth)"
                cols="auto"
                class="pr-0"
              >
                <v-avatar color="success" size="25">
                  <v-icon dark small>mdi-check-bold</v-icon>
                </v-avatar>
              </v-col>

              <v-col
                v-else-if="isSignpostConfirmed(auth.auths) && isAuthCanceled(auth)"
                cols="auto"
                class="pr-0"
              >
                <v-avatar color="error" size="25">
                  <v-icon dark small>mdi-close-thick</v-icon>
                </v-avatar>
              </v-col>

              <v-col cols="auto">
                <span class="headline font-weight-bold text-decoration-underline text-left">
                  {{ `${auth.contact.firstname} ${auth.contact.lastname}` }}
                </span>
              </v-col>

              <v-col v-if="Object.keys(auth.auths).length">
                {{ getAuthTitle(auths) }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content v-if="isSignpostConfirmed(auth.auths)">
            <AmlContentCard v-if="isNotBankId(auth)" :auth-data="auth" />
            <BankIdContentCard v-else :auth-data="auth" />
          </v-expansion-panel-content>

          <v-expansion-panel-content v-else>
            <v-card elevation="0">
              {{ $t('aml.auth.not_filled') }}
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions v-if="canContinueToSigning" class="pt-10 px-0">
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-auto">
            <v-btn
              color="primary"
              x-large
              min-width="14rem"
              :block="$vuetify.breakpoint.mobile"
              :loading="isSubmitted"
              @click="submit"
            >
              {{ $t('general.continue_to_signing') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { apiAuthorizedRequest } from '@/services/ApiService';
import Loader from '@/components/Loader';
import AmlContentCard from '@contract/components/AmlContentCard';
import BankIdContentCard from '@contract/components/BankIdContentCard';

export default {
  name: 'VerificationContent',
  components: {
    AmlContentCard,
    BankIdContentCard,
    Loader,
  },
  data() {
    return {
      loaded: false,
      submitted: false,
      modalDecline: false,
      panels: [0],
    };
  },
  props: {
    mainContractId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      auths: 'verification/auths',
      isAllAuthsDone: 'verification/isAllAuthsDone',
      contract: 'contract',
    }),
    canContinueToSigning() {
      const contractState = this.contract?.state || 'draft';

      return 'draft' === contractState && this.isAllAuthsDone;
    },
    isLoading() {
      return !this.loaded;
    },
    isSubmitted() {
      return this.submitted;
    },
  },
  created() {
    this.loaded = false;
    this.fetchAuthState(this.$route.params.contract_id).then(() => {
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions({
      fetchAuthState: 'verification/fetchAuthState',
    }),
    getAuthTitle(auth) {
      return this.$t(
        `auth.group.default.${
          'undefined' === typeof auth?.auths?.bankId ? 'signi_verification' : 'bank_id'
        }`,
      );
    },
    isNotBankId(auth) {
      return 'undefined' === typeof auth?.auths?.bankId;
    },
    isAuthDone(auth) {
      let isDone = true;

      Object.keys(auth.auths).forEach((authPart) => {
        if (auth.auths[authPart].status !== 'ok') {
          isDone = false;
        }
      });

      return isDone;
    },
    isSignpostConfirmed(auth) {
      return Object.keys(auth)?.length > 0;
    },
    isAuthCanceled(authentication) {
      return Object.keys(authentication.auths).every((auth) => {
        return (
          'expired' === authentication.auths[auth].status ||
          'ok' === authentication.auths[auth].status
        );
      });
    },
    submit() {
      this.submitted = true;

      apiAuthorizedRequest(
        'PATCH',
        `/api/v1/auths/contracts/${this.$route.params.contract_id}/submit`,
      )
        .then(() => {
          this.$router.push({
            name: 'createContent',
            params: {
              contract_id: this.$route.params.contract_id,
              workspace_id: this.$route.params.workspace_id,
            },
          });
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'));
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.aml-content__button {
  text-align: left !important;
}
</style>
