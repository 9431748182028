<template>
  <v-layout align-center justify-center fill-height ma-0>
    <v-layout column align-center justify-center px-2>
      <div
        v-if="isApproved"
        class="pending-state--text d-block text-truncate text-center font-weight-medium"
      >
        {{ $t('docs.pending.generic') }}
      </div>

      <v-btn
        v-else
        v-bind="size"
        :color="customColor || buttonColor"
        :disabled="isAvailableSign"
        :loading="isSubmitted"
        class="d-inline-block sign-button px-2 px-md-3"
        max-width="12rem"
        width="100%"
        :dark="!isAvailableSign"
        :sign-btn-identifier="identifier"
        @click="onSignRequest"
      >
        <div v-if="isOneDeviceSigning" style="width: 100%">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" class="d-block text-truncate">
              {{ $t('contract.sign') }}
            </v-col>
            <v-col cols="12" class="text-center font-weight-light text-caption">
              <span
                v-html="signIdentityTitle"
                class="d-block text-center font-weight-light text-caption text-truncate"
              ></span>
            </v-col>
          </v-row>
        </div>

        <span v-else :style="fitTextToButton" class="text-truncate">{{ $t('contract.sign') }}</span>
      </v-btn>
    </v-layout>

    <SignWithCertificate
      v-if="isSigningWithCertificate"
      v-model="signWithCertificate"
      :contract-id="contract.contract_id || contract.id"
      :active-contract-id="contract.id"
      :contract-title="contract.title"
      :sign-identity-id="signIdentity.id"
      :sign-identity-email="signIdentity.email"
      :sign-identity-position-uuid="position.uuid"
    />
  </v-layout>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { mapActions, mapGetters } from 'vuex';
import { mapState as mapPiniaState } from 'pinia';
import { useBrandingStore } from '@/stores/branding';
import { ContractService } from '@/services/ContractService';
import { fitTextSize } from '@/common/reusable/text';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import { signWithBankIdSign } from '@contract/services/signContractService';
import { WorkflowHelper } from '@/helpers/WorkflowHelper';
import { environment } from '@/config/environment';
import SignWithCertificate from '@signing/components/SignWithCertificate';

export default {
  name: 'SignButton',
  components: { SignWithCertificate },
  props: {
    identifier: {
      type: Number,
      required: true,
    },
    signIdentity: {
      type: Object,
      required: true,
    },
    sign: {
      type: Function,
      default: undefined,
    },
    isOneDeviceSigning: {
      type: Boolean,
      default: false,
    },
    customColor: {
      type: String,
      default: 'primary',
    },
    position: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      approved: false,
      signWithCertificate: false,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      canSignDocument: 'signing/isAllSignIdentitySignaturesConfirmed',
      unfinishedSignatures: 'signing/unfinishedSignatures',
      currentContractSignIdentity: 'signIdentity',
    }),
    ...mapPiniaState(useBrandingStore, {
      brandingPrimaryColor: 'brandingPrimaryColor',
    }),
    isAvailableSign() {
      const signIdentityFullName = `${this.signIdentity.firstname} ${this.signIdentity.lastname}`;
      const currentContractSignIdentityFullName = `${this.currentContractSignIdentity.firstname} ${this.currentContractSignIdentity.lastname}`;
      return signIdentityFullName !== currentContractSignIdentityFullName;
    },
    isApproved() {
      return this.approved;
    },
    size() {
      const sizes = {
        xs: this.isOneDeviceSigning ? '' : 'x-small',
        sm: 'large',
        md: 'x-large',
        lg: 'x-large',
        xl: 'x-large',
      }[this.$vuetify.breakpoint.name];

      return sizes ? { [sizes]: true } : {};
    },
    buttonColor() {
      return this.brandingPrimaryColor;
    },
    fitTextToButton() {
      const textSize = fitTextSize(this.position?.relative_width, 1.2);

      return null === textSize ? '' : `font-size: ${textSize}px`;
    },
    isSigningWithCertificate() {
      return 'sign_certificate' === this.signIdentity?.contract_role || false;
    },
    signIdentityTitle() {
      const assembleTitle = (text) => `<span>${text}</span>`;

      if (this.signIdentity?.variable_position) {
        return assembleTitle(this.signIdentity.variable_position);
      }

      return [
        `${this.signIdentity.firstname} ${this.signIdentity.lastname}`,
        this.signIdentity?.organization_name,
      ]
        ?.map((a) => {
          return a ? assembleTitle(a) : '';
        })
        ?.join(', ');
    },
    isSubmitted() {
      return this.submitted;
    },
  },
  methods: {
    ...mapActions({
      approveSign: 'signing/approveSign',
    }),
    onSignRequest() {
      this.approveSign(this.identifier).then(() => {
        if (this.isSigningWithCertificate) {
          this.signWithCertificate = true;
        } else if (this.canSignDocument(this.signIdentity.id)) {
          this.onSignProcess();
        } else {
          this.approved = true;

          const unfinishedSignatures = this.unfinishedSignatures(this.signIdentity.id);

          if (unfinishedSignatures?.length > 0) {
            this.$notification.success(
              `${this.$t('signing.number_of_waiting_signatures')}: ${unfinishedSignatures?.length}`,
            );
          }

          this.$nextTick(() => {
            const target = document.querySelector(
              `.page-signature button[sign-btn-identifier="${unfinishedSignatures[0]?.identifier}"]`,
            );

            if (target) {
              this.$vuetify.goTo(target, {
                duration: 800,
                offset: 500,
                easing: 'easeInOutCubic',
              });
            }
          });
        }
      });
    },
    onSignProcess() {
      if ('sign_bank_id_sign' === this.signIdentity.contract_role) {
        this.submitted = true;

        const url = window.location.href;
        const payload = {
          error_url: `${url}${url.includes('?') ? '&' : '?'}bankIdResult=failure`,
          redirect_url: `${url}${url.includes('?') ? '&' : '?'}bankIdResult=success`,
          locale: i18n.locale,
        };

        if (this.$route.params?.hash) {
          payload.redirect_url = this.contract?.attachments?.length
            ? `${url}${url.includes('?') ? '&' : '?'}bankIdResult=success`
            : `${environment.getAppUrl()}${
                this.$router.resolve({
                  name: 'signing-completed',
                  params: {
                    email: this.signIdentity?.email,
                    hash: this.$route.params.hash,
                  },
                }).href
              }`;
        }

        return (async () => {
          await signWithBankIdSign({
            authToken: this.$route.params?.hash || null,
            contractId: this.$store.state?.currentContractId || this.contract?.id,
            payload,
          }).catch((err) => {
            this.submitted = false;
            this.$notification.error(getErrorResponseMessage(err));
          });
        })();
      }

      if (typeof this.sign !== undefined) {
        this.sign();
      } else {
        WorkflowHelper.clickOnSignLink({
          contract: this.contract,
          fastsign: (preloadedSignature) => {
            ContractService.sendSignatureAuthorized(
              {
                signature_date: preloadedSignature.date,
                signature_place: preloadedSignature.place,
              },
              preloadedSignature.image,
              this.contract.id,
              this.contract.current_sign_identity.id,
              [],
            )
              .then((resp) => {
                if (
                  (400 === resp.code && 'Smlouva je již uzavřená' === resp.title) ||
                  resp.code >= 400
                ) {
                  return this.$notification.error(`${this.$t('general.error')} - ${resp.title}`);
                }

                if ('completed' === resp.state) {
                  window.dataLayer.push({
                    event: 'contract_proposal_sign_completed',
                  });
                }

                this.processAcceptedOrFastSignedContract(
                  resp,
                  WorkflowHelper.getNextUnsignedAfterApprove(resp),
                );
              })
              .catch(() => {
                this.$notification.error(this.$t('contract.errors.sign'));
              });
          },
          routes: {
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.$route.params.contract_id,
            },
          },
        });
      }
    },
    processAcceptedOrFastSignedContract(response, unApprovedContract) {
      const contract = response;

      if (unApprovedContract) {
        this.$router.push({
          name: 'documentsDetail',
          params: {
            workspace_id: this.$route.params.workspace_id,
            contract_id: unApprovedContract.id,
          },
        });
        location.reload();
      } else {
        if (this.contract.one_device && contract.state !== 'completed') {
          this.$router.push({
            name: 'documentsDetailCounterparty',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.contract.main_contract_id || this.contract.id,
            },
            query: {
              viewContract: true,
              complete: true,
            },
          });
        } else {
          this.$store.commit('setContract', contract);
          this.$router.push({
            name: 'contractsCollectionCreated',
            params: {
              contractId: `${this.contract.id}`,
              workspaceId: `${this.$route.params.workspace_id}`,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(button.sign-button) {
  z-index: 99999;
}

.pending-state--text {
  font-size: clamp(0.2rem, 1.8vw, 1rem);
  width: 100%;
}

:deep(.download-button) a {
  color: inherit !important;
  font-weight: inherit;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
