<template>
  <v-dialog :value="showDialog" max-width="650px">
    <v-card width="100%" class="pa-4 pb-10 d-flex flex-column">
      <v-card-title class="mt-6">
        <h1>
          {{ $t('contract.replace.signer') }}
        </h1>
      </v-card-title>
      <v-btn absolute right icon @click="showDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="mt-3">
        <v-row>
          <v-col class="col-9">
            <v-card v-if="!isNewPartySelected" flat>
              <SInputSearch
                :contacts="getContacts()"
                :loadingContacts="loadingContacts"
                :label="`${$t('contract.signs.signer_placeholder')}`"
                :is-dynamic-list="false"
                :current-id="signIdentity.docs_person_id || signIdentity.id"
                :open-search-id="openSearchId"
                @onSignerSelect="selectNewSignee($event)"
                @toggleSearch="toggleOpenSearchId($event)"
                @onSearchTermChange="filterSearch($event)"
                @onCreateNewUserClick="openIdentityModal"
              />

              <v-divider class="my-8" v-if="!signIdentity.is_proposer"></v-divider>

              <v-btn
                v-if="!signIdentity.is_proposer"
                color="primary"
                width="100%"
                height="3.5rem"
                :block="$vuetify.breakpoint.mobile"
                outlined
                @click="identityModal = true"
              >
                <v-icon class="mr-2"> mdi-account-plus </v-icon>

                {{ $t('contract.create.new_signer') }}
              </v-btn>
            </v-card>

            <div v-else>
              <v-card class="ma-0 pa-0" flat outlined>
                <v-list class="ma-0 pa-0">
                  <v-list-item>
                    <v-list-item-avatar>
                      <VerificationBadge
                        :contact="
                          selectedSignatureParty.updatedIdentity
                            ? selectedSignatureParty.updatedIdentity
                            : selectedSignatureParty
                        "
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          selectedSignatureParty.firstname ||
                          selectedSignatureParty.updatedIdentity.firstname
                        }}
                        {{
                          selectedSignatureParty.lastname ||
                          selectedSignatureParty.updatedIdentity.lastname
                        }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        {{
                          selectedSignatureParty.email ||
                          selectedSignatureParty.updatedIdentity.email
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn @click="assignExistingUser" icon>
                        <v-icon> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action>
                      <v-btn @click="selectedSignatureParty = {}" icon>
                        <v-icon> mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
          </v-col>

          <v-col class="col-2">
            <v-btn
              @click="confirmReplace"
              color="primary"
              :loading="loading"
              :disabled="!isNewPartySelected"
              x-large
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <Identity
      v-if="identityModal"
      class="modal-phone-off"
      :modalConfig="identityModalConfig"
      @close="hideSearchAndModalIfOpened()"
      @upsertIdentity="selectNewSignee"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService';
import SInputSearch from '@/common/components/SInputSearch';
import Identity from '@/views/create/components/Identity.vue';
import VerificationBadge from '@/features/contacts/components/VerificationBadge';
import { fetchContractSignatureRoles } from '@/features/contract/services/contractService';
import { apiAuthorizedRequest } from '@/services/ApiService';

export default {
  name: 'EditSignatureDialog',
  components: { VerificationBadge, SInputSearch, Identity },
  props: {
    value: Boolean,
    signIdentity: {
      type: Object,
      required: true,
    },
    participantName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      allAvailableUsers: [],
      filteredUsers: [],
      identityModal: false,
      identityModalConfig: {},
      selectedSignatureParty: {},
      openSearchId: null,
      searchUser: '',
      loadingContacts: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      workspaceById: 'workspaceById',
      signatures: 'signing/signatures',
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isProposer() {
      return this.signIdentity.is_proposer;
    },
    isNewPartySelected() {
      return Object.keys(this.selectedSignatureParty).length !== 0;
    },
  },
  created() {
    this.loadingContacts = true;
    if (this.signIdentity?.is_proposer) {
      if (this.signIdentity.variable_position && !this.signIdentity?.email) {
        return fetchContractSignatureRoles(this.contract.id, this.participantName).then(
          (response) => {
            this.allAvailableUsers = response.map((item) => item.user);
            this.loadingContacts = false;
          },
        );
      }

      return apiAuthorizedRequest(
        'GET',
        `api/v2/contract/${this.contract.id}/workspace/member/list`,
      ).then((response) => {
        this.allAvailableUsers = response.map((item) => {
          return {
            ...item.user,
            signatureFooter: item.signatureFooter,
          };
        });
        this.loadingContacts = false;
      });
    }

    return ContractService.getCounterparty(this.contract.id, '').then((response) => {
      this.allAvailableUsers = response;
      this.loadingContacts = false;
    });
  },
  methods: {
    selectNewSignee(docsPerson) {
      this.selectedSignatureParty = docsPerson.updatedIdentity || docsPerson;
      this.openSearchId = null;
      this.identityModal = false;
    },
    assignExistingUser() {
      this.identityModal = true;

      if (this.signIdentity?.is_proposer) {
        return (this.identityModalConfig.editedSignIdentity = Object.assign(
          this.selectedSignatureParty,
          {
            type: this.signIdentity?.type,
          },
        ));
      }

      return (this.identityModalConfig.editedSignIdentity = this.selectedSignatureParty);
    },
    confirmReplace() {
      if (this.signIdentity.variable_position) {
        return this.$emit('replace-variable-position', this.selectedSignatureParty);
      }

      return this.$emit('replace-signee', this.selectedSignatureParty);
    },
    toggleOpenSearchId(e) {
      this.openSearchId = e;
    },
    filterSearch(query) {
      this.searchUser = query;

      if (!this.signIdentity?.is_proposer) {
        this.loadingContacts = true;

        return ContractService.getCounterparty(this.contract.id, query).then((response) => {
          this.allAvailableUsers = response;
          this.loadingContacts = false;
        });
      }
    },
    getContacts() {
      if (0 === this.searchUser?.length || !this.signIdentity?.is_proposer) {
        return this.allAvailableUsers;
      }

      return this.allAvailableUsers.filter(
        (user) =>
          user.lastname?.toLowerCase().includes(this.searchUser.toLowerCase()) ||
          user.firstname?.toLowerCase().includes(this.searchUser.toLowerCase()) ||
          user.email?.toLowerCase().includes(this.searchUser.toLowerCase()),
      );
    },
    hideSearchAndModalIfOpened() {
      this.openSearchId = null;

      if (!this.identityModal) {
        return;
      }

      this.identityModal = false;
    },
    openIdentityModal(newEmail) {
      this.identityModalConfig = {
        newContactEmail: newEmail,
      };
      this.identityModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.v-dialog) {
  overflow: visible !important;
}
</style>
