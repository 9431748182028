<template>
  <v-container
    :data-page-index="pageNumber - 1"
    class="page document-page pa-0 pa-md"
    :class="{
      'document-page__bordered': !isLastPage,
      'page--last': isLastPage,
    }"
    fluid
  >
    <DocumentPageLoader v-if="!documentLink" />
    <pdf
      v-else
      :id="pageId"
      :src="documentLink"
      :page="pageNumber"
      @loading="loaded"
      @numpages="onPagesNumberChange"
      @error="onError"
    >
      <template slot="loading">
        <DocumentPageLoader />
      </template>
    </pdf>

    <PageSignature
      v-for="signature in signatures"
      :key="signature.identifier"
      :identifier="signature.identifier"
      :position="signature.position"
      :sign-identity="signature.signIdentity"
      :sign="sign"
      :custom-color="customColor"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import pdf from '@digitalfactorycz/pdf-viewer';
import DocumentPageLoader from '@/common/skeletons/DocumentPageLoader';
import PageSignature from '@signing/components/PageSignature';

export default {
  name: 'DocumentPage',
  components: {
    PageSignature,
    DocumentPageLoader,
    pdf,
  },
  props: {
    pageNumber: {
      type: Number,
      required: true,
      validation: (value) => {
        return 0 < value && value <= this.numberOfPages;
      },
    },
    pageId: {
      type: String,
      required: true,
      validation: (value) => {
        return value.includes(this.pageNumber);
      },
    },
    canShowSignatures: {
      type: Boolean,
      default: false,
    },
    sign: {
      type: Function,
      default: undefined,
    },
    customColor: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      pageLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      documentLink: 'pdfDocument/documentLink',
      numberOfPages: 'pdfDocument/numberOfPages',
      pageSignatures: 'signing/pageSignatures',
    }),
    isLastPage() {
      return this.pageNumber === this.numberOfPages;
    },
    signatures() {
      return this.canShowSignatures && this.pageLoaded ? this.pageSignatures(this.pageNumber) : [];
    },
  },
  methods: {
    ...mapActions({
      setNumberOfPages: 'signing/setNumberOfPages',
    }),
    loaded(e) {
      this.pageLoaded = !e;
      this.$emit('on-loaded', !e);
    },
    onPagesNumberChange(e) {
      this.setNumberOfPages(e);
    },
    onError(e) {
      console.log(`${this.pageNumber} error`, e);
    },
  },
};
</script>

<style lang="scss" scoped>
.document-page {
  position: relative !important;
  z-index: 0;

  &__bordered {
    border-bottom: 3px dashed #ccc;
  }
}
</style>
