<template>
  <article ref="pageSignature">
    <v-card v-if="isSignable" v-bind="attrs" elevation="0">
      <SignButton
        :identifier="identifier"
        :is-one-device-signing="isOneDeviceSigning"
        :sign-identity="signIdentity"
        :sign="sign"
        :custom-color="customColor"
        :position="position"
      />
    </v-card>

    <v-card v-else v-bind="attrs" elevation="0">
      <div
        v-if="isSignatureHeaderVisible"
        class="page-signature__header d-flex align-center px-2 py-0 text-center"
        :style="fitTextToButton"
      >
        <span class="d-inline-block text-truncate font-weight-medium">
          {{ signatureHeaderContent }}
        </span>
      </div>

      <v-card-text
        v-if="!isSigned"
        class="page-signature__content justify-center fill-height pa-0"
        :class="{
          'page-signature__content--no-header': !isSignatureHeaderVisible,
        }"
      >
        <SignByOtherPreview
          :participant-name="participantName"
          :show-menu="showMenu"
          :isVariablePosition="isVariablePosition"
          :isProposer="isProposer"
          :isContractOwner="isContractOwner"
          :relativeWidth="position.relative_width"
          @edit-signature="showEditSignatureDialog = true"
        />
      </v-card-text>
    </v-card>

    <EditSignatureDialog
      v-if="showEditSignatureDialog"
      v-model="showEditSignatureDialog"
      :loading="loading"
      :sign-identity="signIdentity"
      :participant-name="participantName"
      @replace-variable-position="replaceVariablePositionWithUser"
      @replace-signee="replaceSignee"
    />
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { i18n } from '@/plugins/i18n';
import { fitTextSize } from '@/common/reusable/text';
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';
import SignButton from '@signing/components/SignButton';
import SignByOtherPreview from '@signing/components/SignByOtherPreview';
import { setUserFromVariableRole } from '@/features/contract/services/contractService';
import { authorizedApiRequest } from '@/services/ApiService';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';

export default {
  name: 'PageSignature',
  components: {
    SignByOtherPreview,
    SignButton,
    EditSignatureDialog: () => import('@/features/signing/components/EditSignatureDialog'),
  },
  props: {
    identifier: {
      type: Number,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    signIdentity: {
      type: Object,
      required: true,
    },
    sign: {
      type: Function,
      default: undefined,
    },
    customColor: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      resizeObserver: null,
      signatureRatio: 0.54989126,
      pageRation: 1.41284404,
      x: 0,
      y: 0,
      showMenu: false,
      selectedUserName: '',
      showEditSignatureDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      documentInfo: 'pdfDocument/documentInfo',
      profile: 'profile',
    }),
    attrs() {
      return {
        class: [
          'page-signature',
          'ma-0',
          'pa-0',
          !this.isSigned && !this.isSignable ? 'page-signature--border' : '',
        ],
        style: [
          `background: ${this.isSigned || this.isSignable ? 'transparent' : '#ffffff'} !important`,
          `top: ${this.position?.offset_top}%`,
          `left: ${this.position?.offset_left}%`,
          `width: ${this.position?.relative_width || 45}%`,
          `height: ${(this.position?.relative_width * this.signatureRatio) / this.pageRation}%`,
          `cursor: ${this.canAssignFromVariablePosition ? 'pointer' : ''}`,
        ].join('; '),
      };
    },
    canAssignFromVariablePosition() {
      return this.signIdentity.is_proposer && !!this.signIdentity?.variable_position;
    },
    isContractOwner() {
      return this.documentInfo.user_id === this.profile.id;
    },
    fitTextToButton() {
      const textSize = fitTextSize(this.position?.relative_width, 0.9);

      return null === textSize ? '' : `font-size: ${textSize}px`;
    },
    isProposer() {
      return this.contract?.current_sign_identity?.is_proposer || false;
    },
    isSigned() {
      return this.signIdentity?.is_signed || this.signIdentity?.is_signed_with_certificate || false;
    },
    isSignable() {
      return (!this.isSigned && this.signIdentity?.is_signable_by_current_user) || false;
    },
    isSignatureHeaderVisible() {
      return (
        this.position?.show_header && 'stamp' !== this.signIdentity.contract_role && !this.isSigned
      );
    },
    isOneDeviceSigning() {
      return this.contract?.one_device || false;
    },
    isVariablePosition() {
      return this.signIdentity?.variable_position && !this.signIdentity.email;
    },
    participantName() {
      if (this.signIdentity?.variable_position && !this.signIdentity.email) {
        return `${this.signIdentity.variable_position}`;
      }

      return `${this.signIdentity.firstname} ${this.signIdentity.lastname}`;
    },
    signatureDate() {
      return getFormattedDateByLocale({
        date: this.signIdentity?.signature_date,
        locale: this.contract?.locale || i18n.locale,
      });
    },
    signaturePlace() {
      return this.signIdentity?.signature_place;
    },
    signatureHeaderContent() {
      if (!this.isSigned) {
        return this.$t('docs.pending.generic');
      }

      return this.signIdentity?.signature_date
        ? this.$t('signature.header.place_day', this.contract.locale, {
            place: this.signaturePlace,
            day: this.signatureDate,
          })
        : this.$t('signature.header.placeholder');
    },
    workspaceId() {
      return this.$route.params.workspace_id;
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.onResizePage);
    this.resizeObserver?.observe(this.$refs.pageSignature?.parentElement);
  },
  beforeDestroy() {
    if (this.$refs.pageSignature?.parentElement) {
      this.resizeObserver?.unobserve(this.$refs.pageSignature.parentElement);
    }
  },
  methods: {
    onResizePage() {
      const currentPage = this.$refs.pageSignature?.parentElement || undefined;

      if (currentPage) {
        const width = currentPage.getBoundingClientRect()?.width;
        const height = currentPage.getBoundingClientRect()?.height;

        this.pageRation = height / width;
      }
    },
    replaceVariablePositionWithUser(user) {
      this.loading = true;

      let signIdentityIds = Array.of(this.signIdentity.id);
      let userId = user.id;
      let closestSignature = {};

      this.selectedUserName = `${user.firstname} ${user.lastname}`;

      for (let attachment of this.contract.attachments) {
        const identitiesWithPosition = [];

        if (this.contract.id !== attachment.id) {
          attachment.sign_identities.filter((element) => {
            if (element.variable_position === this.participantName && !element?.email) {
              identitiesWithPosition.push(element);
            }
          });

          closestSignature = identitiesWithPosition.reduce(
            (previousSignature, currentSignature) => {
              return Math.abs(currentSignature.positions[0].offset_top - this.position.offset_top) <
                Math.abs(previousSignature.positions[0].offset_top - this.position.offset_top)
                ? currentSignature
                : previousSignature;
            },
          );
          signIdentityIds.push(closestSignature.id);
        }
      }

      setUserFromVariableRole(userId, signIdentityIds)
        .then(() => {
          this.showEditSignatureDialog = false;
        })
        .catch((err) => this.$notification.error(getErrorResponseMessage(err)))
        .finally(() => {
          this.loading = false;
          location.reload();
        });
    },
    async replaceSignee(user) {
      const newDocsPerson = {
        docsPerson: {
          email: user?.email,
          firstname: user?.firstname,
          lastname: user?.lastname,
          docsRole: this.signIdentity.is_proposer ? 'proposer' : 'counterparty',

          mobile: user?.mobile || user?.mobile_tmp,

          identityForm: user?.type || 'nature',
          birthDate: user?.number || null,

          organizationName:
            user?.invoice_info?.invoice_organization_name || user?.organization_name,

          organizationPosition: user?.position || user?.organization_position,

          organizationStreet: user?.invoice_info?.invoice_street || user?.organization_street,

          organizationZip: user?.invoice_info?.invoice_zip || user?.organization_zip,

          organizationIco: user?.invoice_info?.invoice_ico || user?.organization_ico,

          organizationDic: user?.invoice_info?.invoice_dic || user?.organization_dic,
        },
        signatureFooter: user?.signatureFooter,
      };

      await this.replaceSignatureParty(newDocsPerson);
    },
    replaceSignatureParty(newSignee) {
      this.loading = true;

      authorizedApiRequest({
        method: 'PUT',
        endpoint: `api/v2/contract/${this.contract?.id}/docsPerson/replace/${this.signIdentity.docs_person_id}`,
        data: newSignee,
      })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          this.$notification.error(getErrorResponseMessage(err));
        })
        .finally(() => {
          this.loading = false;
          this.showEditSignatureDialog = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-signature {
  position: absolute;

  .disabled-click {
    pointer-events: none;
  }

  &--border {
    border: 1px solid #b5c1d7;
    border-radius: 8px;
  }

  &__header {
    border-bottom: 1px solid #b5c1d7;
    border-radius: 8px 8px 0 0;
    background: #f1f6fc;
    color: #2d2e2e;
    font-size: clamp(0.2rem, 0.8vw, 0.8rem);
    width: 100%;
    height: 100%;
    max-height: 20%;

    span {
      width: 100%;
      height: fit-content;
    }

    &--signed {
      border-bottom: unset !important;
      background: transparent;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    max-height: 80%;

    &--no-header {
      max-height: 100%;
    }
  }
}
</style>
